type PaletteeKeys =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'A100'
  | 'A200'
  | 'A400'
  | 'A700'
  | 'contrast';
export type Palette = Record<PaletteeKeys, string>;

export const NEUTRAL: Palette = {
  '50': '#F5F6FB',
  '100': '#E4E5F2',
  '200': '#D4D7E6',
  '300': '#C4C7D6',
  '400': '#ABAFC6',
  '500': '#9396A8',
  '600': '#6E7183',
  '700': '#4F525E',
  '800': '#363742',
  '900': '#252632',
  A100: '#f3f9ff',
  A200: '#c1defe',
  A400: '#8dc2ff',
  A700: '#74b5ff',
  contrast: '#2E404F',
};

export const PRIMARY: Palette = {
  '50': '#F1F3FF',
  '100': '#DDE2FF',
  '200': '#BAC2FF',
  '300': '#8695FF',
  '400': '#5167FE',
  '500': '#334CF7',
  '600': '#0D2AED',
  '700': '#0F28CE',
  '800': '#0B1E9A',
  '900': '#08187B',
  A100: '#e1e8ff',
  A200: '#aec0ff',
  A400: '#7b98ff',
  A700: '#6284ff',
  contrast: '#ffffff',
};

export const SECONDARY: Palette = {
  '50': '#FFF5ED',
  '100': '#FFE8D4',
  '200': '#FFCDA8',
  '300': '#FFAA70',
  '400': '#F77C4C',
  '500': '#EE6631',
  '600': '#E3531B',
  '700': '#D24D19',
  '800': '#B84214',
  '900': '#953611',
  A100: '#ffe8d420',
  A200: '#ffcda820',
  A400: '#ff7b3720',
  A700: '#c72a0720',
  contrast: '#ffffff',
};

export const INFO: Palette = {
  '50': '#e3f2ff',
  '100': '#badeff',
  '200': '#8cc8ff',
  '300': '#5db1ff',
  '400': '#3ba1ff',
  '500': '#1890ff',
  '600': '#1588ff',
  '700': '#117dff',
  '800': '#0e73ff',
  '900': '#0861ff',
  A100: '#ffffff',
  A200: '#f4f7ff',
  A400: '#c1d4ff',
  A700: '#a7c3ff',
  contrast: '#ffffff',
};

export const SUCCESS: Palette = {
  '50': '#E6F2EE',
  '100': '#B9F2E0',
  '200': '#90EACD',
  '300': '#6DE0BB',
  '400': '#43D7A7',
  '500': '#29CC97',
  '600': '#42B48E',
  '700': '#289C75',
  '800': '#16825E',
  '900': '#0C6C4C',
  A100: '#aeffb8',
  A200: '#7bff8b',
  A400: '#48ff5e',
  A700: '#2fff47',
  contrast: '#ffffff',
};

export const WARNING: Palette = {
  '50': '#FFF5E7',
  '100': '#FCECD6',
  '200': '#F8D8AB',
  '300': '#FFCC80',
  '400': '#FFC268',
  '500': '#FFB649',
  '600': '#F5A733',
  '700': '#E49C31',
  '800': '#D28E2A',
  '900': '#BD7E20',
  A100: '#ffffff',
  A200: '#fffaf2',
  A400: '#ffe4bf',
  A700: '#ffd9a6',
  contrast: '#111827',
};

export const ERROR: Palette = {
  '50': '#FFE7E7',
  '100': '#FFC9C9',
  '200': '#FCA9A9',
  '300': '#F48B8B',
  '400': '#FF7A7A',
  '500': '#FC6868',
  '600': '#FC5757',
  '700': '#F24848',
  '800': '#D33737',
  '900': '#B42828',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffcece',
  A700: '#ffb5b4',
  contrast: '#ffffff',
};

export const DANGER: Palette = {
  '50': '#FFFFF0',
  '100': '#FEFCBF',
  '200': '#FAF089',
  '300': '#F6E05E',
  '400': '#ECC94B',
  '500': '#D69E2E',
  '600': '#B7791F',
  '700': '#975A16',
  '800': '#744210',
  '900': '#5F370E',
  A100: '#ffffff',
  A200: '#FDF8DD',
  A400: '#FFF1B5',
  A700: '#FFE06E',
  contrast: '#ffffff',
};
export const TERTIARY: Palette = {
  '50': '#FBF4FF',
  '100': '#F1E0F9',
  '200': '#DDB9EE',
  '300': '#CEA3E1',
  '400': '#C38CDC',
  '500': '#BE77DE',
  '600': '#B660DD',
  '700': '#A23AD1',
  '800': '#8E24BE',
  '900': '#6D1793',
  A100: '#ffffff',
  A200: '#FDF8DD',
  A400: '#FFF1B5',
  A700: '#FFE06E',
  contrast: '#ffffff',
};

export const GRAY: Palette = {
  '50': '##E1F2F6',
  '100': '#FEFCBF',
  '200': '#FAF089',
  '300': '#F6E05E',
  '400': '#363740',
  '500': '#B5D7DE',
  '600': '#B7791F',
  '700': '#A3C7CE',
  '800': '#89AEB6',
  '900': '#5F370E',
  A100: '#ffffff',
  A200: '#FDF8DD',
  A400: '#FFF1B5',
  A700: '#FFE06E',
  contrast: '#ffffff',
};
