import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import EventService from 'core/services/events/events.service';
import HttpService from 'core/services/api/http.service';
import CurrentUserUsecase from 'core/usecases/currentUser.usecase';
import LegalEntityUsecase from 'core/usecases/legalEntity.usecase';
import { AppEventsNames, RolesEnum } from 'core/common/constants';
import {
  type ExhaustiveValue,
  type SwaggerPathsKeys,
  type SwaggerPathsMethods,
  type SwaggerRequestBodyJsonContent,
  type SwaggerRequestPathVar,
  type SwaggerRequestResponse,
} from 'core/swagger';
import FormService, { FormProperties, FormRequired } from 'core/services/form/form.service';
import { FormValidationHandler } from 'core/services/form/form.port';
import CacheService from '../services/cache/cache.service';

export interface LoginProps {
  username: string;
  password: string;
  mobile?: string;
  captcha?: string;
}

interface LoginResponse {
  expiresAt: number;
  token: string;
  code?: number;
  message?: string;
}

type SetPasswordFormInitialValues = Record<keyof FormProperties, ExhaustiveValue>;

interface SetPasswordFormReturn<
  I = SetPasswordFormInitialValues | Promise<SetPasswordFormInitialValues>,
> {
  properties: FormProperties;
  getInitialValues: () => I;
  required: FormRequired;
  validate: FormValidationHandler;
  submit: <P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    data: object,
  ) => Promise<SwaggerRequestResponse<P, M> | undefined>;
}

class AuthUseCase implements Usecase {
  private isIDLE = false;

  private tokenRefreshRatio = 0.5;

  private TTLTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  private isOnLogout = false;

  constructor(
    private eventService: EventService,
    private store: AppStore,
    private cacheService: CacheService,
    private http: HttpService,
    private formService: FormService,
    private currentUserUsecase: CurrentUserUsecase,
    private legalEntityUsecase: LegalEntityUsecase,
  ) {
    this.eventService.subscribe(AppEventsNames.OnUnauthorized, () => {
      if (!this.isOnLogout) {
        this.logout();
      }
    });
  }

  getRoles() {
    return [];
  }

  isGranted() {
    return true;
  }

  /**
   * Login
   */
  async login(credentials: LoginProps): Promise<boolean> {
    const loginResponse = await this.http.request<LoginResponse>({
      method: 'POST',
      url: '/api/login_check',
      body: credentials,
      contentType: 'application/ld+json',
    });

    const updatedAt = Date.now();

    if (!loginResponse.data) return false;

    this.store.setState((state) => {
      state.auth.isLogged = true;
      state.auth.token = loginResponse.data?.token;
      state.auth.expiresAt = loginResponse.data?.expiresAt;
      state.auth.updatedAt = updatedAt;
    });

    this.currentUserUsecase.getCurrentUser().then((isSucceed) => {
      if (isSucceed) {
        const legalEntityId = this.store.getState((state) => {
          return state.user.current?.legalEntity?.id;
        });
        const isLegalEntityAdmin = this.store.getState((state) => {
          return state.user.current?.roles?.includes(RolesEnum.ROLE_MY_LEGAL_ENTITY);
        });

        if (legalEntityId) {
          this.legalEntityUsecase.getLegalEntitySettings(legalEntityId);
          if (isLegalEntityAdmin) {
            this.legalEntityUsecase.getLegalEntity(legalEntityId);
          }
        }
      }
    });
    this.startTTLTimer();

    this.eventService.emit(AppEventsNames.OnLogin);

    return true;
  }

  /**
   * Logout
   */
  async logout() {
    this.isOnLogout = true;

    if (this.store.getState((state) => state.auth.isLogged)) {
      await this.http.request<void>({
        method: 'GET',
        url: '/api/logout',
        contentType: 'application/ld+json',
      });

      this.stopTTLTimer();

      this.store.reset();

      this.cacheService.reset();

      this.eventService.emit(AppEventsNames.OnLogout);
    }

    this.isOnLogout = false;
    return true;
  }

  /**
   * Check login status
   */
  check() {
    const { expiresAt, updatedAt } = this.store.getState((state) => state.auth);

    if (!expiresAt || !updatedAt) {
      return this.logout();
    }

    if (
      Date.now() >= updatedAt + (expiresAt - updatedAt) * this.tokenRefreshRatio &&
      !this.isIDLE
    ) {
      return this.loginRefresh();
    }
  }

  /**
   * Set IDLE (user inactive)
   */
  setIDLE(state: boolean) {
    this.isIDLE = state;
  }

  /**
   * Reset password
   */
  public passwordReset() {
    const path: SwaggerPathsKeys = '/api/user-password-reset';
    const method: SwaggerPathsMethods<typeof path> = 'post';

    const { properties, required, validate } = this.formService.createForm(path, method);

    const handleSubmit: SetPasswordFormReturn['submit'] = (data) => {
      const formattedData = this.formService.unformatData(data);
      return this.submitForm(path, method, formattedData);
    };

    return { properties, required, validate, submit: handleSubmit };
  }

  /**
   * Refresh login token
   */
  private async loginRefresh() {
    const refreshResponse = await this.http.request<LoginResponse>({
      method: 'POST',
      url: '/api/token/refresh',
      contentType: 'application/ld+json',
    });

    const updatedAt = Date.now();

    if (!refreshResponse.data) return false;

    this.store.setState((state) => {
      state.auth.isLogged = true;
      state.auth.expiresAt = refreshResponse.data?.expiresAt;
      state.auth.updatedAt = updatedAt;
    });

    this.startTTLTimer();

    return true;
  }

  /**
   * Start TTL timer
   */
  private startTTLTimer() {
    // const expiresAt = this.store.getState((state) => state.auth.expiresAt);
    // if (this.TTLTimer) {
    //   this.stopTTLTimer();
    // }
    // if (expiresAt) {
    //   // expires at is seconds while Javascript Date is milliseconds
    //   const duration = expiresAt * 1000 - Date.now();
    //   this.TTLTimer = setTimeout(() => {
    // this.eventService.emit(AppEventsNames.OnUnauthorized, { message: 'token expired' });
    //   }, duration);
    // }
  }

  /**
   * Stop TTL timer
   */
  private stopTTLTimer() {
    clearTimeout(this.TTLTimer);
  }

  private async submitForm<
    P extends SwaggerPathsKeys,
    M extends SwaggerPathsMethods<P>,
    D extends SwaggerRequestBodyJsonContent<P, M>,
    V extends SwaggerRequestPathVar<P, M>,
  >(path: P, method: M, data: D, pathVar?: V): ReturnType<SetPasswordFormReturn['submit']> {
    return this.http
      .requestFromSwagger(path, method, {
        body: data,
        pathVar: pathVar,
      })
      .then((response) => response.data);
  }

  /**
   * GET SIGNUP FORM
   */
  getSignupForm() {
    const { properties, required, validate, getValues } = this.formService.createForm(
      '/api/private/prospects',
      'post',
    );

    const submit = async <D>(data: D) => {
      return this.submitForm('/api/private/prospects', 'post', getValues(data));
    };

    return {
      validate,
      submit,
      required,
      properties,
    };
  }
}

export default AuthUseCase;
