import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap, RolesEnum } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import { isStringArray } from 'core/common/utils/predicatesType';
import EnumEntity from 'core/entities/enum.entity';

class RolesUseCase implements Usecase {
  private shadowRoles: string[] = [
    RolesEnum.ROLE_USER,
    
    // FIXME: Thoses roles will be deleted from the backend
    // We filter them till they are not present in the db
    RolesEnum.ROLE_ADMIN_CUSTOM_FORM,
    RolesEnum.ROLE_ADMIN_INVOICE,
    RolesEnum.ROLE_ADMIN_ASSET,
    RolesEnum.ROLE_ADMIN_CONTRACTUAL_COMMITMENT,
    "ROLE_ADMIN_DASHBOARD",
    RolesEnum.ROLE_DISTRIBUTOR,
    RolesEnum.ROLE_USER_NOTIFICATION,
    RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION
  ];

  constructor(private store: AppStore, private enumEntity: EnumEntity) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  getAvailableRoles = async () => {
    return this.enumEntity
      .get({
        pathVar: {
          id: 'EnumUserRolesAvailableType',
        },
      })
      .then((response) => {
        if (isStringArray(response.data)) {
          return response.data;
        }

        return [];
      });
  };

  public getShadowRoles() {
    return this.shadowRoles;
  }
}

export default RolesUseCase;
