import React from 'react';
import Card from 'src/web/design_system/Card';

const withCard = <P extends object>(Component: React.ComponentType<P>) => {
  return ({ inCard = true, ...props }: P & { inCard?: boolean }) => {
    if (inCard) {
      return (
        <Card.Container>
          <Component {...(props as P)} />
        </Card.Container>
      );
    }
    return (
      <div>
        <Component {...(props as P)} />
      </div>
    );
  };
};

export default withCard;
