import { useEffect, useState } from 'react';
import { createCss } from 'src/web/common/styles/createCss';
import { useTheme } from 'src/web/common/theme';
import useTranslate from 'src/web/common/translate/useTranslate';
import Card from 'src/web/design_system/Card';
import Chart from 'src/web/design_system/Chart';
import Displayer from 'src/web/design_system/Displayer';
import Skeleton from 'src/web/design_system/Skeleton';
import Text from 'src/web/design_system/Text';
import { SiteType } from '..';
import useAsstAvailability from '../hooks/useAsstAvailability';
import { AssetAvailabilityTitleStyle, guageStyle, widgetStyle } from '../styles';
import ErrorWidget from './errorWidget';
import { useCore } from 'src/web/common/core';
import { AssetsTypesEnum } from 'src/core/common/constants';

interface AssetAvailabilityProps {
  selectedAsset?: string;
  selectedSite?: SiteType;
}

const AssignmentRate = ({ selectedAsset, selectedSite }: AssetAvailabilityProps) => {
  const { translate } = useTranslate();
  const { store } = useCore();
  const assetType = selectedAsset === 'all' ? '' : selectedAsset;
  const siteId = selectedSite?.id === 'all' ? '' : selectedSite?.id;

  const { isLoading, data } = useAsstAvailability(assetType, siteId);
  const { theme } = useTheme();

  const customAssetTitle = store.getState(
    (state) => state.legalEntity.settings?.settings?.customAssetTitle,
  );

  const percentageStyle = createCss(`
        display: flex;
        justify-content: center;
        flex-direction: row;
        background-color: ${theme.palettes.primary?.[500]};
        color:${theme.colors.white};;
        font-size: 0.875rem;
        font-weight: 700;
        border-radius: .5rem;
        padding: 0.125rem .81rem;
    `);

  const dataStyle = createCss(`
        margin-top: -2.5rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap:.5em;
        align-items:center;
    `);

  const totalStats = assetType
    ? data?.assetAssignmentStatsByCategory?.assetTypes?.[assetType ?? '']
    : data?.assetAssignmentStatsByCategory?.total;

  const assetTitle = assetType
    ? ` ${translate('global.terms.of')} ${
        assetType === AssetsTypesEnum.customAsset
          ? customAssetTitle
          : translate(`${assetType}s.title`).toLowerCase()
      }`
    : '';
  
  const assetTitleRate = assetType
    ? `${
        assetType === AssetsTypesEnum.customAsset
          ? customAssetTitle
          : translate(`${assetType}s.title`)
      }`
    : `${translate('global.terms.assets')}`;

  return (
    <Skeleton isLoading={isLoading} width={'100%'} height="400px">
      <Card.Container className={widgetStyle}>
        <div className={AssetAvailabilityTitleStyle}>
          <Text variant="elementTitle">
            {translate('dashboard.awardRate.title')}
            {assetTitle}
          </Text>
        </div>
        {data && data.assetAssignmentStatsByCategory ? (
          <Displayer justifyContent={'center'} alignItems={'center'} innerSpacing={1}>
            <div className={guageStyle}>
              <Chart.Gauge value={totalStats?.attributionRate} />
            </div>
            <div className={dataStyle}>
              <Text variant="textMedium" color={theme.palettes.neutral?.[400]}>
                {assetTitleRate} {translate('global.terms.assigned')}
              </Text>
              <div className={percentageStyle}>
                <Text variant="textNormal" align="center" fontWeight={700}>
                  {`${totalStats?.numAssigned ?? 0} / ${totalStats?.numTotal ?? 0}`}
                </Text>
              </div>
            </div>
          </Displayer>
        ) : (
          <ErrorWidget message={translate('dashboard.unavailableData.message')} inCard={false} />
        )}
      </Card.Container>
    </Skeleton>
  );
};

export default AssignmentRate;
