import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import FormService from 'core/services/form/form.service';
import { ExhaustiveValue } from 'core/swagger';
import AttachmentSiteEntity from 'core/entities/attachmentSite.entiry';

class SitesUseCase implements Usecase {
  private currentOne: Record<string, unknown> = {};

  constructor(
    private store: AppStore,
    private formService: FormService,
    private attachmentSiteEntity: AttachmentSiteEntity,
  ) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * Get sites
   */
  public async getAllSites() {
    return this.attachmentSiteEntity.getAll().then((response) => {
      if (response.status === 403) {
        return [];
      }
      return response.data ?? [];
    });
  }

  public async getOneSiteById(id: string) {
    const res = await this.attachmentSiteEntity.getOne({
      pathVar: {
        id: id,
      },
    });
    if (res.status?.toString().charAt(0) !== '2') throw new Error('Site unavailable');
    return res.data;
  }

  /**
   * Get create form
   */
  public getCreateSiteForm() {
    const { properties, required, validate } = this.formService.createForm(
      this.attachmentSiteEntity.getPathAll(),
      'post',
    );

    const handleSubmit = async <D>(data: D) => {
      return this.attachmentSiteEntity
        .post({
          body: this.formService.unformatData(data),
        })
        .then((response) => !!response.data);
    };

    return {
      properties,
      initialValues: {
        location_status: 1,
      },
      required,
      validate,
      submit: handleSubmit,
    };
  }

  /**
   * Get edit form
   */
  public getEditSiteForm(id: string) {
    const { properties, required, validate } = this.formService.createForm(
      this.attachmentSiteEntity.getPathOne(),
      'put',
    );

    this.currentOne = {};

    const getInitialValues = async () => {
      const currentData = await this.attachmentSiteEntity
        .getOne({
          pathVar: {
            id: id,
          },
        })
        .then((response) => response.data);

      if (currentData) {
        this.currentOne = this.formService.formatData(currentData);

        const mapping = Object.keys(properties).map((key) => [key, this.currentOne[key]]);

        const mappedInitialValues: Record<keyof typeof properties, ExhaustiveValue> =
          Object.fromEntries(mapping);

        return mappedInitialValues;
      }

      return {};
    };

    const handleSubmit = async <D>(data: D) => {
      return this.attachmentSiteEntity
        .put({
          pathVar: {
            id: id,
          },
          body: this.formService.unformatData({ ...this.currentOne, ...data }),
        })
        .then((response) => !!response.data);
    };

    return {
      properties,
      initialValues: getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }

  /**
   * Delete site
   */
  public async deleteSite(id: string) {
    return this.attachmentSiteEntity
      .delete({
        pathVar: {
          id: id,
        },
      })
      .then(() => true)
      .catch(() => false);
  }
}

export default SitesUseCase;
