import { styled } from '@mui/material/styles';
import { useTheme } from 'src/web/common/theme';
import MenuItem from '../../design_system/Menu/MenuItem';

export const selectedButtonStyle = (colorSelected: string, colorHovered: string) => ({
  backgroundColor: `${colorSelected}`,
  '&:hover': {
    backgroundColor: `${colorHovered}`,
  },
});

const MenuItemSide = styled(MenuItem)(({ isSelected }) => {
  const { theme } = useTheme();
  return {
    '&.MuiListItemButton-root': {
      borderRadius: theme.shape.defaultRadius,
      marginBottom: '2px',
      fontWeight: 700,
      ...(!isSelected
        ? {}
        : selectedButtonStyle(
            theme.palettes.primary?.[50] ?? '',
            theme.palettes.neutral?.[50] ?? '',
          )),
    },
    '&.MuiTypography-root': {
      fontWeight: 900,
    },
  };
});

export default MenuItemSide;
