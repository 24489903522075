import { FC } from 'react';
import Form from 'src/web/components/FormInput';
import { AssetsFilterProps } from './assetsFilter';
import { FilterItemStyle } from '../styles';
import { useTheme } from 'src/web/common/theme';
import useTranslate from 'src/web/common/translate/useTranslate';
import { EntitiesEnumType } from '..';

const AssetsFilterSelect: FC<AssetsFilterProps> = ({
  assets,
  selectedAsset,
  setSelectedAsset,
  getAssetIcon,
}) => {
  const { theme } = useTheme();
  const { translate } = useTranslate();

  const handleChange = (selecteOption: EntitiesEnumType) => {
    setSelectedAsset(selecteOption);
  };

  return (
    <div className={FilterItemStyle(theme)}>
      <Form.Select
        options={assets}
        value={selectedAsset}
        onChange={(selecteOption) => handleChange(selecteOption as EntitiesEnumType)}
        renderOptionItem={(value) => ({
          label: (
            <>
              {value !== 'all' && getAssetIcon(value)}{' '}
              {translate(
                value !== 'all' ? `global.asset.type.${value}` : 'dashboard.filter.material.all',
              )}
            </>
          ),
        })}
      />
    </div>
  );
};

export default AssetsFilterSelect;
