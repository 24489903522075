import { type FC } from 'react';
import { mergeCss } from 'src/web/common/styles/createCss';
import { useTheme } from 'src/web/common/theme';
import Icon from 'src/web/components/Icon';
import Text from 'src/web/design_system/Text';
import { AssetAvailabilityTitleStyle, messageWidgetStyle, widgetStyle } from '../styles';
import withCard from '../HOC/withCard';

interface ErrorWidgetInterface {
  title?: string;
  message?: string;
  inCard?: boolean;
}

const ErrorWidget: FC<ErrorWidgetInterface> = ({ message, title }) => {
  const { theme } = useTheme();
  return (
    <div className={mergeCss(widgetStyle)}>
      {title && (
        <div className={AssetAvailabilityTitleStyle}>
          <Text variant="elementTitle">{title}</Text>
        </div>
      )}
      <div className={messageWidgetStyle(theme.palettes.neutral?.[400])}>
        <Icon.WarningAmber size="large" color="action" />
        <Text variant="textMedium" color={theme.palettes.neutral?.[400]}>
          {message}
        </Text>
      </div>
    </div>
  );
};

export default withCard(ErrorWidget);
