import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import DashboardAssetAssignmentEntity from 'core/entities/dashboardAssetAssignment.entity';
import DashboardAssetCostStatsEntity from 'core/entities/dashboardAssetCostStats.entity';

class DashboardUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private dashboardAssetAssignmentEntity: DashboardAssetAssignmentEntity,
    private dashboardAssetCostStatsEntity: DashboardAssetCostStatsEntity,
  ) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  public async getAssetAssignmentStats(assetType?: string, siteId?: string) {
    const queryParams: Record<string, string | undefined> = {
      assetType: assetType || undefined,
      siteId: siteId || undefined,
    };

    const resource = this.dashboardAssetAssignmentEntity
      .getAll({
        query: queryParams,
      })
      .then((response) => response.data);
    return resource;
  }

  public async getBuyingCostStats(assetType?: string, siteId?: string, category?: string) {
    const queryParams: Record<string, string | undefined> = {
      assetType: assetType || undefined,
      siteId: siteId || undefined,
      category: category || undefined,
    };

    const resource = this.dashboardAssetCostStatsEntity
      .getBuyingCostStats({
        query: queryParams,
      })
      .then((response) => response.data ?? []);
    return resource;
  }

  public async getRentalCostStats(assetType?: string, siteId?: string, category?: string) {
    const queryParams: Record<string, string | undefined> = {
      assetType: assetType || undefined,
      siteId: siteId || undefined,
      category: category || undefined,
    };

    const resource = this.dashboardAssetCostStatsEntity
      .getRentalCostStats({
        query: queryParams,
      })
      .then((response) => response.data ?? []);
    return resource;
  }

  public async getCurrentMonthCostStats(
    assetType?: string,
    siteId?: string,
    isAssigned?: boolean,
    category?: string,
  ) {
    const queryParams: Record<string, string | boolean | undefined> = {
      assetType: assetType || undefined,
      category: category || undefined,
      siteId: siteId || undefined,
      isAssigned: isAssigned !== undefined ? isAssigned : undefined,
    };

    const resource = this.dashboardAssetCostStatsEntity
      .getCurrentMonthCostStats({
        query: queryParams,
      })
      .then((response) => response.data ?? []);
    return resource;
  }
}

export default DashboardUseCase;
