import { type FC } from 'react';
import PageContainerPartial from './partials/pageContainerPartial';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import Skeleton from 'src/web/design_system/Skeleton';
import useEditUserForm from './hooks/useEditUserForm';
import RoleEmblemGroup from 'src/web/components/RoleEmblemGroup';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import useDisplayRoles from 'src/web/common/roles/useDisplayRoles';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import { isString } from 'src/core/common/utils/predicatesType';
import useFormComponent from 'src/web/components/FormInput/useFormComponent';
import { useParams, useSearchParams } from 'react-router-dom';
import { ROUTE_USERS } from 'src/web/common/router/constantes';

const EditUserPage: FC = () => {
  const {
    form,
    availableRoles,
    currentRoles,
    isRolesLoading,
    isLoading,
    handleToggleRole,
    optionsServices,
    handleSearch,
  } = useEditUserForm();
  const { translate } = useTranslate();

  const roles = useDisplayRoles(availableRoles);
  const { formatFormMessage } = useFormComponent();

  const handleDepartmentChange = (value?: string | string[]) => {
    if (Array.isArray(value)) {
      form.items.department.handleChange(value[0]);
    } else {
      form.items.department.handleChange(null);
    }
  };
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { id } = useParams();

  return (
    <PageContainerPartial
      pageTitle={translate('users.edit.title')}
      submitLabel={translate('users.edit.submit')}
      canSubmit={form.isDirty && !form.hasErrors}
      onSubmit={(e) => {
        form.handleSubmit(e);
      }}
      isLoading={isLoading}
      backNavigate={`${ROUTE_USERS}/${id}?${queryString}`}
    >
      <Card.Basic title={translate('users.humanResource.title')}>
        <Skeleton isLoading={isLoading} width="100%" height="300px">
          <Displayer innerSpacing={2}>
            <Form.TextAdapted
              item={form.items.firstname}
              label={translate('users.firstname.label')}
            />
            <Form.TextAdapted
              item={form.items.lastname}
              label={translate('users.lastname.label')}
            />
            <Autocomplete
              label={translate('users.department.label')}
              noOptionsText={translate('users.department.autocomplete.noOptions')}
              isRequired={form.items.department.required}
              options={
                // TODO: remove any and get the good type and data from the backend
                optionsServices
                  ? optionsServices?.map((options: any) => ({
                      label: options.department,
                      value: options.department,
                    }))
                  : []
              }
              onSearch={(value) => {
                form.items.department.handleChange(value);
                handleSearch(value);
              }}
              selectValue={(item) => item.value}
              onChange={(value) => handleDepartmentChange(value)}
              value={
                isString(form?.items?.department?.formValue)
                  ? {
                      label: form?.items?.department?.formValue,
                      value: form?.items?.department?.formValue,
                    }
                  : undefined
              }
              isError={!!form.items.department.errors}
              errorMessage={formatFormMessage(form.items.department)}
            />
            <Form.SelectAdapted
              item={form.items.status}
              label={translate('users.status.label')}
              renderOptionItem={(value) => ({
                label: translate(`users.status.option.${value}.label`),
              })}
            />
          </Displayer>
        </Skeleton>
      </Card.Basic>

      <Card.Basic title={translate('users.roles.title')}>
        <Skeleton isLoading={isRolesLoading} width="100%" height="200px">
          <RoleEmblemGroup
            roles={roles.map(({ key, label, icon: RoleIcon }) => ({
              role: key,
              label,
              icon: <RoleIcon size="large" />,
              isSelected: currentRoles.includes(key),
              onClick: () => handleToggleRole(key),
            }))}
          />
        </Skeleton>
      </Card.Basic>
    </PageContainerPartial>
  );
};

export default EditUserPage;
