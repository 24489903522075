import Injector from 'core/.framework/injector';
import { createAdapters } from './adapters';
import services from './services';
import entities from './entities';
import usecases from './usecases';

export type AppCoreServices = ReturnType<typeof AppCoreInit>;

export function AppCoreInit() {
  const adapters = createAdapters();

  const modules = Injector.init({
    adapters,
    ...services,
    ...entities,
    ...usecases,
  });

  return {
    eventService: modules.eventService,
    store: modules.store,
    inventoryUsecase: modules.inventoryUsecase,
    authUsecase: modules.authUsecase,
    assignmentUseCase: modules.assignmentUseCase,
    setAssetUsercase: modules.setAssetUsercase,
    setPersonUsecase: modules.setPersonUsecase,
    assetDetailsUseCase: modules.assetDetailsUseCase,
    sivinUseCase: modules.sivinUseCase,
    // userNotificationsSubscriptionUseCase: modules.userNotificationsSubscriptionUseCase,
    currentUserUsecase: modules.currentUserUsecase,
    guestUsecase: modules.guestUsecase,
    setVehicleUseCase: modules.setVehicleUseCase,
    timeService: modules.timeService,
    personUsercase: modules.personUsercase,
    legalEntityUsecase: modules.legalEntityUsecase,
    sitesUseCase: modules.sitesUseCase,
    documentUsecase: modules.documentUsecase,
    setUserUseCase: modules.setUserUseCase,
    rolesUseCase: modules.rolesUseCase,
    userUseCase: modules.userUseCase,
    deleteAssetUseCase: modules.deleteAssetUseCase,
    // notificationPreviewUseCase: modules.notificationPreviewUseCase,
    tasksItemsUseCase: modules.tasksItemsUseCase,
    boardingUseCase: modules.boardingUseCase,
    // notificationConditionsUsecase: modules.notificationConditionsUsecase,
    cacheService: modules.cacheService,
    // notificationsConfigUsecase: modules.notificationsConfigUsecase,
    customAssetCategoryUseCase: modules.customAssetCategoryUseCase,
    dashboardUseCase: modules.dashboardUseCase,
  };
}
