import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_UPDATE_TAG, ROUTE_USERS } from 'src/web/common/router/constantes';

const useEditUserForm = () => {
  const { setUserUseCase, rolesUseCase, userUseCase, currentUserUsecase } = useCore();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [optionsServices, setOptionsServices] =
    useState<Awaited<ReturnType<typeof userUseCase.getServices>>>(undefined);
  const [searchValue, setSearchValue] = useState('');

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const loadServices = async () => {
    if (searchValue.length > 1) {
      const response = await userUseCase.getServices(searchValue);
      if (response) {
        setOptionsServices(response);
      }
    } else {
      setOptionsServices(undefined);
    }
  };

  useEffect(() => {
    loadServices();
  }, [searchValue]);

  // get user id
  const userId = useMemo(() => {
    const chunks = location.pathname.split('/');
    if (chunks.slice(-1).includes(ROUTE_UPDATE_TAG)) {
      return chunks.splice(-2)[0];
    }

    navigate('/');
    return '';
  }, [location]);

  const { properties, initialValues, required, validate, submit } = useMemo(
    () => setUserUseCase.getEditForm(userId),
    [],
  );
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [isRolesLoading, setIsRolesLoading] = useState(true);

  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      try {
        const response = await submit(data);
        if (response && typeof response === 'object' && 'id' in response && response.id) {
          const id = response.id as string;

          navigate(`${ROUTE_USERS}/${id}?${queryString}`);
        } else {
          console.error('Invalid response received:', response);
        }
        
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {

        // Only update the store if the user is the current user
        if (await currentUserUsecase.isCurrentUser(userId)) {
          await currentUserUsecase.getCurrentUser();
        }
        setIsLoading(false);
      }
    },
  });

  const handleToggleRole = (role: string) => {
    setCurrentRoles((current) => {
      if (current.includes(role)) {
        return [...current].filter((data) => data !== role);
      } else {
        return [...current, role];
      }
    });
  };

  // Get a ENUM list from the backend of available roles
  useEffect(() => {
    rolesUseCase
      .getAvailableRoles()
      .then((roles) => setAvailableRoles(roles))
      .finally(() => setIsRolesLoading(false));
  }, []);

  useEffect(() => {
    form.imperativeChangeField('roles', currentRoles);
  }, [currentRoles]);

  // Update form values from current data
  useEffect(() => {
    if (initialValues) {
      Promise.resolve(initialValues).then((values) => {
        if ('roles' in values && Array.isArray(values.roles)) {
          setCurrentRoles(values.roles);
        }

        for (const key in values) {
          form.imperativeChangeField(key, values[key]);
        }

        setIsLoading(false);
      });
    }
  }, [initialValues]);

  return {
    form,
    isLoading,
    isRolesLoading,
    availableRoles,
    currentRoles,
    handleToggleRole,
    optionsServices,
    handleSearch,
  };
};

export default useEditUserForm;
