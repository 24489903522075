import { FC } from 'react';
import { LineChart as MuiLineChart } from '@mui/x-charts/LineChart';
import Text from '../Text';

type ScaleType = 'time' | 'point' | 'linear' | 'band' | 'log' | 'pow' | 'sqrt' | 'utc';
interface LineChartProps {
  data?: number[];
  alineX?: string[];
  color?: string;
  title?: string;
  height?: number;
  scale: ScaleType;
}
const LineChart: FC<LineChartProps> = ({ title, data, alineX, height = 350, color, scale }) => {
  return (
    <>
      {title && <Text variant="elementTitle">{title}</Text>}
      <MuiLineChart
        data-testid="line-chart-svg"
        xAxis={[
          {
            scaleType: scale,
            data: alineX,
          },
        ]}
        series={[
          {
            data: data,
            color,
          },
        ]}
        height={height}
      />
    </>
  );
};

export default LineChart;
