import { useMemo, type FC, type PropsWithChildren } from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { css } from '@emotion/css';
import { GuiTypographyVariants } from '../Theme';

interface TypographyProps {
  variant?: GuiTypographyVariants;
  color?: string;
  align?: 'center' | 'right' | 'left' | 'justify';
  fontWeight?: 200 | 400 | 600 | 700 | 900;
  tag?: 'h1' | 'h2' | 'h3' | 'div' | 'p' | 'span';
  textOverflow?: boolean;
  title?: string;
  whiteSpace?: 'wrap' | 'nowrap';
}

const textOverflowStyle = css`
  max-width: 100%;
  white-space: normal;
  overflow: hidden;
  display: inline-block;
  text-wrap: nowrap;
`;

function fromVariantToMuiVariant(variant?: string): {
  theme: Variant;
  component: React.ElementType;
} {
  switch (variant) {
    case 'pageTitle':
      return { theme: 'h1', component: 'h1' };
    case 'sectionTitle':
      return { theme: 'h2', component: 'h2' };
    // deprecated
    case 'title':
    case 'elementTitle':
      return { theme: 'h3', component: 'h3' };
    // deprecated
    case 'subtitle':
    case 'textLarge':
      return { theme: 'subtitle1', component: 'span' };
    case 'textMedium':
      return { theme: 'subtitle2', component: 'span' };
    // deprecated
    case 'small':
    case 'textSmall':
      return { theme: 'body2', component: 'span' };
    case 'textVerySmall':
      return { theme: 'caption', component: 'span' };
    case 'textAction':
      return { theme: 'button', component: 'span' };
    // deprecated
    case 'linkLarge':
      return { theme: 'h4', component: 'h4' };
    case 'linkDefault':
      return { theme: 'h5', component: 'h5' };
    case 'linkSmall':
      return { theme: 'h6', component: 'h6' };
    case 'default':
    case 'textNormal':
    default:
      return { theme: 'body1', component: 'span' };
  }
}

const Text: FC<PropsWithChildren<TypographyProps>> = (props) => {
  const def = useMemo(() => fromVariantToMuiVariant(props.variant), [props.variant]);
  return (
    <MuiTypography
      className={props.textOverflow ? textOverflowStyle : ''}
      variant={def.theme}
      color={props.color}
      align={props.align}
      fontWeight={props.fontWeight}
      component={props.tag ?? def.component}
      paragraph={['p'].includes(props.tag ?? '')}
      textOverflow={props.textOverflow ? 'ellipsis' : 'inherit'}
      title={props.title}
      whiteSpace={props.whiteSpace}
    >
      {props.children}
    </MuiTypography>
  );
};

export default Text;
