import Card from 'src/web/design_system/Card';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import { HighlightCardStyle, HighlightCardText } from '../styles';
import { FC } from 'react';
import { useTheme } from 'src/web/common/theme';
import useTranslate from 'src/web/common/translate/useTranslate';
import useBreakpoint from 'src/web/common/utils/useBreakpoints';
import { formatNumber } from 'src/core/common/transformNumber';
import Skeleton from 'src/web/design_system/Skeleton';

interface HighlightCardProps {
  title?: string;
  value?: number;
  type?: 'number' | 'cost';
  color?: string;
  error?: any;
  isLoading?: boolean;
}

const HighlightCard: FC<HighlightCardProps> = ({
  title = 'Cout',
  value = 0,
  type = 'cost',
  color,
  isLoading,
}) => {
  const { theme } = useTheme();
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('down', 'lg');
  const isTablet = useBreakpoint('down', 'xl');

  return (
    <Card.Container className={HighlightCardStyle(isMobile)}>
      <Displayer layout={isTablet ? 'column' : 'row'} flexWrap={'nowrap'} innerSpacing={'1.5rem'}>
        <Skeleton isLoading={isLoading} width={'30%'}>
          <Text variant="pageTitle" color={color} whiteSpace="nowrap">
            {value && formatNumber(value, true, 1)}
            {type === 'cost' ? ' €' : ''}
          </Text>
        </Skeleton>
        <div className={HighlightCardText}>
          <Displayer innerSpacing={0.5}>
            <Skeleton isLoading={isLoading} width={'60%'} height="16px">
              <Text variant="textLarge">{title}</Text>
            </Skeleton>
            <Skeleton isLoading={isLoading} width={'40%'} height="16px">
              <Text variant="textSmall" color={theme.palettes.neutral?.[500]}>
                {translate('dashboard.highlight.curruntMonth')}
              </Text>
            </Skeleton>
          </Displayer>
        </div>
      </Displayer>
    </Card.Container>
  );
};

export default HighlightCard;
