import { nanoid } from 'nanoid';
import { useTheme } from 'src/web/common/theme';
import useTranslate from 'src/web/common/translate/useTranslate';
import LinearGauge from 'src/web/components/Indicators/LinearGauge';
import Card from 'src/web/design_system/Card';
import Skeleton from 'src/web/design_system/Skeleton';
import Text from 'src/web/design_system/Text';
import useAsstAvailability from '../hooks/useAsstAvailability';
import { AssetAvailabilityStyle, AssetAvailabilityTitleStyle, widgetStyle } from '../styles';
import ErrorWidget from './errorWidget';
import { SiteType } from './filterPartial';
import { AssetsTypesEnum } from 'src/core/common/constants';
import { customOrder } from 'src/web/common/utils/customOrder';
import { useCore } from 'src/web/common/core';

interface AssetAvailabilityProps {
  selectedAsset?: string;
  selectedSite?: SiteType;
}

const colorStates: { [key: string]: string } = {
  '0': 'success',
  '0.49': 'warning',
  '0.66': 'error',
};

const getColorState = (percentage: number, theme: any): string | undefined => {
  return Object.keys(colorStates).reduce((acc, threshold) => {
    return percentage >= Number(threshold)
      ? theme.palettes[colorStates[Number(threshold)]]?.[500]
      : acc;
  }, theme.palettes.success?.[600]);
};

const AssetAvailabilityPartial = ({ selectedAsset, selectedSite }: AssetAvailabilityProps) => {
  const { theme } = useTheme();
  const { translate } = useTranslate();
  const { store } = useCore();
  const { isLoading, data } = useAsstAvailability(
    selectedAsset === 'all' ? '' : selectedAsset ?? '',
    selectedSite?.id === 'all' ? '' : selectedSite?.id,
  );

  const isLicence = selectedAsset === AssetsTypesEnum.licence;
  const isService = selectedAsset === AssetsTypesEnum.service;
  const assetType = selectedAsset === 'all' ? '' : selectedAsset ?? '';
  const customAssetTitle = store.getState(
    (state) => state.legalEntity.settings?.settings?.customAssetTitle,
  );
  const assetTitle = assetType
  ? ` ${translate('global.terms.of')} ${
      assetType === AssetsTypesEnum.customAsset
        ? customAssetTitle
        : translate(`${assetType}s.title`).toLowerCase()
    }`
  : '';

  const handleLabel = (assetTypeOpt: string, category?: string) => {
    const labelsMap = {
      [AssetsTypesEnum.computer]: `${assetTypeOpt}s.formFactor.option.${category}.label`,
      [AssetsTypesEnum.telephone]: `${assetTypeOpt}s.formFactor.option.${category}.label`,
      [AssetsTypesEnum.telephoneLine]: `${assetTypeOpt}s.formFactor.option.${category}.label`,
      [AssetsTypesEnum.card]: `${assetTypeOpt}s.purpose.option.${category}.label`,
      [AssetsTypesEnum.licence]: `${assetTypeOpt}s.dashboard.label`,
      [AssetsTypesEnum.service]: `${assetTypeOpt}s.dashboard.label`,
      [AssetsTypesEnum.vehicle]: category,
      [AssetsTypesEnum.customAsset]: category,
    };
    return translate(labelsMap[assetTypeOpt as keyof typeof labelsMap] || 'other');
  };

  const renderAssetGauge = (
    label: string,
    value: number,
    max: number,
    color: string | undefined,
  ) => (
    <div key={nanoid()} className={AssetAvailabilityStyle}>
      <LinearGauge label={label} value={value} max={max} color={color} />
    </div>
  );

  const calculateTotalStats = (key: string) => {
    return data?.assetAssignmentStatsByCategory?.assetTypes?.[assetType]?.categories?.reduce(
      (acc: number, el: { [key: string]: number }) => acc + el[key],
      0,
    );
  };

  /**
   * Note: We return a combined data for services and licenses because we don't use anymore them.
   */
  const renderAssetCategories = () => {
    if (isService || isLicence) {
      const totalNumTotal = calculateTotalStats('numTotal');
      const totalNumAssigned = calculateTotalStats('numAssigned');
      return renderAssetGauge(
        handleLabel(assetType),
        totalNumTotal - totalNumAssigned,
        totalNumTotal,
        getColorState(totalNumAssigned / totalNumTotal, theme),
      );
    } else {
      return data?.assetAssignmentStatsByCategory.assetTypes?.[assetType]?.categories?.map(
        (el: { numTotal: number; numAssigned: number; category: string }) =>
          renderAssetGauge(
            handleLabel(assetType, el.category),
            el.numTotal - el.numAssigned,
            el.numTotal,
            getColorState(el.numAssigned / el.numTotal, theme),
          ),
      );
    }
  };

  const renderAllAssets = () => {
    return Object.keys(data?.assetAssignmentStatsByCategory.assetTypes ?? {})
      .sort(customOrder)
      .map((assetTypeKey) => {
        const { numTotal = 0, numAssigned = 0 } =
          data?.assetAssignmentStatsByCategory.assetTypes?.[assetTypeKey] ?? {};

        const label =
          assetTypeKey === AssetsTypesEnum.customAsset
            ? customAssetTitle
            : translate(`${assetTypeKey}s.title`);

        return renderAssetGauge(
          label || '',
          numTotal - numAssigned,
          numTotal,
          getColorState(numAssigned / numTotal, theme),
        );
      });
  };

  const renderContent = () => {
    const totalAssets = data?.assetAssignmentStatsByCategory?.total?.numTotal;
    const selectedAssetTotal =
      data?.assetAssignmentStatsByCategory?.assetTypes?.[assetType]?.numTotal;

    // if the 'all' option is selected and there are available assets
    if (selectedAsset === 'all' && totalAssets > 0) {
      return <div style={{ padding: '25px' }}>{renderAllAssets()}</div>;
    }

    // if a specific asset is selected and there are available assets
    // otherwise show the error card
    if (selectedAsset !== 'all' && selectedAssetTotal > 0) {
      return (
        <div style={{ padding: '25px' }}>
          {assetType.length ? renderAssetCategories() : renderAllAssets()}
        </div>
      );
    }

    return <ErrorWidget message={translate('dashboard.unavailableAsset.message')} inCard={false} />;
  };

  return (
    <Skeleton isLoading={isLoading} width={'100%'} height="400px">
      <Card.Container className={widgetStyle}>
        <div className={AssetAvailabilityTitleStyle}>
          <Text variant="elementTitle">
            {translate('dashboard.assetsAvailability.title')}
            {assetTitle}
          </Text>
        </div>
        {renderContent()}
      </Card.Container>
    </Skeleton>
  );
};

export default AssetAvailabilityPartial;
