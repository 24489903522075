import Icon from 'src/web/components/Icon';
import Skeleton from 'src/web/design_system/Skeleton';
import Text from 'src/web/design_system/Text';
import FormEditMileage from 'src/web/pages/assets/detail/partials/CardMileage/FormEditMileage';
import PopoverMenu from 'src/web/design_system/PopoverMenu';
import { HEADER_ICON_COLOR, HEADER_ICON_SIZE } from 'src/web/components/Header/constants';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import DeleteMileage from './DeleteMileage';
import { mainMileageHistoryContainer, historyBoxContent } from './style';
import { useCore } from 'src/web/common/core';
import { useTheme } from 'src/web/common/theme';
import { nanoid } from 'nanoid';
import useTranslate from 'src/web/common/translate/useTranslate';
import Toolbar from 'src/web/design_system/Toolbar';
import Divider from 'src/web/design_system/Divider';
import Card from 'src/web/components/Card';

interface HistoryMileageProps {
  isLoading: boolean;
  data?: {
    assetId: string;
    value: number;
    date: Date;
  }[];
  reloadMileage: () => void;
  initialMileage?: number | null;
  recordedInitialMileage?: string | null;
}

const HistoryMileage: FC<HistoryMileageProps> = ({
  isLoading,
  data,
  reloadMileage,
  initialMileage,
  recordedInitialMileage,
}) => {
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [activeIdDel, setActiveIdDel] = useState<string | undefined>(undefined);
  const { timeService } = useCore();
  const theme = useTheme();

  const handleEdit = () => {
    reloadMileage();
    setActiveId(undefined);
  };

  const handleDelete = () => {
    reloadMileage();
  };
  const { translate } = useTranslate();

  const editAction = useCallback(
    (id: string) => (
      <PopoverMenu
        isOpen={activeId === id}
        onChange={(state) => {
          setActiveId(state ? id : undefined);
        }}
        horizontal="right"
        icon={<Icon.Close size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
        iconActive={<Icon.Edit size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
      >
        <Card.Container>
          <Card.Toolbar compact>
            <Text variant="textLarge">Modifier ce relevé ?</Text>
          </Card.Toolbar>
          <Card.Content compact>
            <FormEditMileage id={id} onSubmit={handleEdit} />
          </Card.Content>
        </Card.Container>
      </PopoverMenu>
    ),
    [activeId],
  );

  const deleteAction = useCallback(
    (id: string) => {
      return (
        <PopoverMenu
          isOpen={activeIdDel === id}
          onChange={(state) => {
            setActiveIdDel(state ? id : undefined);
          }}
          horizontal="right"
          icon={<Icon.Close size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
          iconActive={<Icon.Delete size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
        >
          <Card.Container>
            <Card.Toolbar compact>
              <Text variant="textLarge">{translate('assets.mileage.delete.modal.title')}</Text>
            </Card.Toolbar>
            <Card.Content compact>
              <DeleteMileage
                id={id}
                onSubmit={handleDelete}
                onClose={() => setActiveIdDel(undefined)}
              />
            </Card.Content>
          </Card.Container>
        </PopoverMenu>
      );
    },
    [activeIdDel],
  );

  const computedata = useMemo(() => {
    const cdata = data?.map((item) => ({
      id: item.assetId,
      isInitial: false,
      value: item.value,
      time: timeService.format(item.date, 'short'),
      actions: (
        <>
          {editAction(item.assetId)}
          {deleteAction(item.assetId)}
        </>
      ),
    }));

    cdata?.push({
      id: nanoid(),
      isInitial: true,
      value: initialMileage ?? 0,
      time: timeService.format(timeService.fromBackend(recordedInitialMileage ?? ''), 'short'),
      actions: (
        <>
          <Text color={theme.theme.palettes.neutral?.[500]} variant="textSmall">
            {translate('vehicles.initialMileage.label')}
            <Icon.StartFlag color="disabled" />
          </Text>
        </>
      ),
    });

    return cdata;
  }, [data, editAction, deleteAction]);

  return (
    <Skeleton width={'100%'} height={'100px'} isLoading={isLoading}>
      <div className={historyBoxContent}>
        {computedata?.map((item, index) => (
          <Fragment key={item.id}>
            <Toolbar
              compact
              className={mainMileageHistoryContainer}
              primaryContent={
                <>
                  <Text
                    variant="textSmall"
                    color={item.isInitial ? theme.theme.palettes.neutral?.[700] : undefined}
                  >
                    <Text fontWeight={600}>{`${item.value} km`}</Text>
                    {` le ${item.time}`}
                  </Text>
                </>
              }
              secondaryContent={item.actions}
            />
            {index < computedata.length - 1 && <Divider />}
          </Fragment>
        ))}
      </div>
    </Skeleton>
  );
};
export default HistoryMileage;
