import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface ToggleButtonGroupProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  fullWidth?: boolean;
  exclusive?: boolean;
  orientation?: 'horizontal' | 'vertical';
  onChange?: (value: string) => void;
  value?: string | string[];
}

const ToggleButtonGroup: FC<PropsWithChildren<ToggleButtonGroupProps>> = ({
  children,
  size = 'small',
  disabled = false,
  fullWidth = false,
  orientation = 'horizontal',
  exclusive,
  onChange,
  value,
  className,
}) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    onChange?.(newValue);
  };
  return (
    <MuiToggleButtonGroup
      sx={{
        width: '40px',
      }}
      className={className}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      orientation={orientation}
      exclusive={exclusive}
      onChange={handleChange}
      value={value}
    >
      {children}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
