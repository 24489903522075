// TO DO: create a port and complete this adapter

import { FormikConfig, FormikValues, useFormik } from 'formik';

export function useFormManager<V extends FormikValues>(props: FormikConfig<V>) {
  const formik = useFormik<V>(props);

  return {
    values: formik.values,
    setFieldValue: formik.setFieldValue,
    setValues: formik.setValues,
    handleSubmit: formik.handleSubmit,
    isDirty: formik.dirty,
    reset: formik.handleReset,
    errors: formik.errors,
  };
}
