import HighlightCard from './highlightCard';
import { HighlightStyle } from '../styles';
import { type FC } from 'react';
import { SiteType } from '..';
import { useTheme } from 'src/web/common/theme';
import useCurrentMonthCost from '../hooks/useCurrentMonthCost';
import useTranslate from 'src/web/common/translate/useTranslate';

interface AssetAvailabilityProps {
  selectedAsset?: string;
  selectedSite?: SiteType;
}

const HighlightsPartial: FC<AssetAvailabilityProps> = ({ selectedAsset, selectedSite }) => {
  const assetType = selectedAsset === 'all' ? '' : selectedAsset;
  const siteId = selectedSite?.id === 'all' ? '' : selectedSite?.id;
  const { isLoading, data } = useCurrentMonthCost(assetType, siteId);
  const { isLoading: unusedGoodsLoading, data: unnusedGoods } = useCurrentMonthCost(
    assetType,
    siteId,
    false,
  );
  const { theme } = useTheme();
  const { translate } = useTranslate();

  return (
    <div className={HighlightStyle}>
      {/* Cost */}
      <HighlightCard
        isLoading={isLoading}
        title={translate('dashboard.highlit.monthlyRentalCost')}
        value={
          data?.assetCostStats?.cost
            ? data?.assetCostStats?.cost
            : 0
        }
      />
      {/* NumAsset */}
      <HighlightCard
        isLoading={isLoading}
        title={translate('dashboard.highlight.unallocatedSubscriptionGoods')}
        value={
          unnusedGoods?.assetCostStats?.numAssets ? unnusedGoods?.assetCostStats?.numAssets : 0
        }
        type="number"
        color={theme.palettes.error?.[500]}
      />
      {/* Cost */}
      <HighlightCard
        isLoading={unusedGoodsLoading}
        title={translate('dashboard.highlight.costsOfUnusedGoods')}
        value={
          unnusedGoods?.assetCostStats?.cost
            ? unnusedGoods?.assetCostStats?.cost
            : 0
        }
        color={theme.palettes.error?.[500]}
      />
    </div>
  );
};

export default HighlightsPartial;
