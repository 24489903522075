import { FC, useEffect, useState } from 'react';
import usePersonInventory from './hooks/useUserInventory';
import { ROUTE_CREATE_TAG, ROUTE_USERS } from 'src/web/common/router/constantes';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import ButtonCreate from 'src/web/components/Button/buttonCreate';
import Text from 'src/web/design_system/Text';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AssetStateChip from 'src/web/components/Chip/AssetStateChip';
import { useCore } from 'src/web/common/core';
import EmptyValue from 'src/web/components/EmptyValue';
import useTranslate from 'src/web/common/translate/useTranslate';
import InventoryCard from 'src/web/components/InventoryCard';
import SearchExpandable from 'src/web/design_system/Input/SearchExpandable';
import useInventoryHelpers from 'src/web/common/inventory/useInventoryHelpers';
import FiltersModalByButton from 'src/web/components/FiltersModalByButton';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import InventoryFilter from 'src/web/components/InventoryFilter';
import { useHistoryPagination } from 'src/web/common/inventory/useHistoryPagination';
import useDisplayRoles from 'src/web/common/roles/useDisplayRoles';

const UserInventoryPage: FC = () => {
  const inventoryData = usePersonInventory(['username', 'roles', 'status', 'lastConnection']);
  const { timeService } = useCore();

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const [inventoryRefresh, setInventoryRefresh] = useState(false);

  const { translate, translateWithPlural } = useTranslate();

  const navigate = useNavigate();
  const inventoryHelpers = useInventoryHelpers();

  const filterIsLoaded = Object.keys(inventoryData.filters).length > 0;

  const { handleChangePage, handleChangeItemPerPage } = useHistoryPagination(
    inventoryData.setItemPerPage,
    inventoryData.setCurrentPage,
    inventoryData.filters,
    filterIsLoaded,
  );

  const filterList = Object.keys(inventoryData.filters);

  const getFilterValuesFromSearchParams = (paramValue: string | null) => {
    return paramValue ? paramValue.split(',').map(decodeURIComponent) : undefined;
  };

  useEffect(() => {
    if (filterIsLoaded) {
      filterList.forEach((filterKey) => {
        const paramValue = searchParams.get(filterKey);
        const filterValues = getFilterValuesFromSearchParams(paramValue);

        if (inventoryData.filters[filterKey] && filterValues) {
          inventoryData.filters[filterKey].onChange(filterValues);
          setInventoryRefresh(true);
        }
      });
    }
  }, [filterIsLoaded]);

  const handleSubmitFilters = () => {
    inventoryData.handleLoadData();
    handleChangePage(1);
    return true;
  };

  useEffect(() => {
    inventoryData.handleLoadData();
  }, [inventoryRefresh]);

  return (
    <>
      <PageHeaderBasic
        title={`${translate('users.title')} ${
          inventoryData.isLoading ? '' : `(${inventoryData.totalItems})`
        }`}
        extraContent={
          <ButtonCreate
            label={translate('users.add.cta')}
            to={`${ROUTE_USERS}/${ROUTE_CREATE_TAG}?${queryString}`}
          />
        }
      />

      <InventoryCard.Container>
        {/* TOOLBAR */}
        <InventoryCard.Toolbar
          primaryContent={
            <FiltersModalByButton edge="start" onSubmitFilters={() => handleSubmitFilters()}>
              <Skeleton isLoading={inventoryData.isLoading} width="100%" height="250px">
                <Displayer innerSpacing={2}>
                  <InventoryFilter
                    item={inventoryData.filters.status}
                    label={translate('assets.asset.status.label')}
                    renderOptionItem={(value) => ({
                      label: translate(`users.status.option.${value}.label`),
                    })}
                  />
                </Displayer>
              </Skeleton>
            </FiltersModalByButton>
          }
          secondaryContent={
            <SearchExpandable
              edge="end"
              onSearch={(val) => inventoryData.handleSearch(val)}
              initialValue={inventoryData.currentSearch}
            />
          }
        />
        {/* CURRENT FILTERS */}

        <InventoryCard.CurrentFilters
          activeFilters={Object.keys(inventoryData.selectedFilters)}
          onClearFilter={(name) => inventoryData.handleClearFilter(name)}
        />
        {/* INVENTORY */}
        <InventoryCard.Inventory
          onClickRow={(id) => navigate(`${ROUTE_USERS}/${id}?${queryString}`)}
          isLoading={inventoryData.isLoading}
          onSort={(key, sortState) =>
            inventoryHelpers.handleSort(inventoryData.sorters, key, sortState)
          }
          rows={inventoryData.rows ?? []}
          columns={inventoryData.columns!.map((column) => ({
            key: column,
            label: () => `${column}`,
            minWidth: () => 350,
            maxWidth: () => 700,
            isFlex: () => true,
            isSortable: () =>
              Object.keys(inventoryData.sorters ?? []).includes(`order[${String(column)}]`),
            renderHeader: (field) => {
              return translate(`users.${field}.label`);
            },
            render: (row) => {
              if (column === 'username') {
                return <Text>{row[column]}</Text>;
              }

              if (column === 'roles') {
                const rolesLength = useDisplayRoles(row[column] ?? []).length
                return (
                  <Text>
                    {translateWithPlural('global.role.label', 'global.roles.label', rolesLength, {
                      count: rolesLength.toString(),
                    })}
                  </Text>
                );
              }

              if (column === 'status') {
                return (
                  <AssetStateChip
                    value={row[column]}
                    label={translate(`users.status.option.${row[column]}.label`)}
                    size="small"
                  />
                );
              }

              if (column === 'lastConnectionDate') {
                return row.lastConnectionDate ? (
                  <Text>{timeService.format(timeService.fromBackend(row[column]), 'short')}</Text>
                ) : (
                  <EmptyValue />
                );
              }
              if (column === 'lastConnection') {
                return (
                  <div>
                    {row?.lastConnectionDate && (
                      <>
                        <Text variant="textSmall" tag="div">
                          {new Date(row.lastConnectionDate).toLocaleDateString(
                            timeService.currentLocale,
                          )}
                        </Text>
                        <Text variant="textSmall">
                          {new Date(row.lastConnectionDate).toLocaleTimeString(
                            timeService.currentLocale,
                          )}
                        </Text>
                      </>
                    )}
                  </div>
                );
              }

              return <Text>{row[column]}</Text>;
            },
          }))}
        />
        {/* PAGINATION */}
        <InventoryCard.Pagination
          paginationCount={Math.ceil(inventoryData.totalItems / inventoryData.itemPerPage)}
          paginationCurrent={inventoryData.currentPage}
          paginationPerPage={inventoryData.itemPerPage}
          onPaginationChange={(value) => handleChangePage(Number(value))}
          onPaginationChunkChange={(value) => handleChangeItemPerPage(Number(value))}
        />
      </InventoryCard.Container>
    </>
  );
};

export default UserInventoryPage;
