import { FC, useState } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import Highlights from './partial/highlightsPartial';
import Filter from './partial/filterPartial';
import AssetAvailability from './partial/assetAvailability';
import { AssetsTypesEnum } from 'src/core/common/constants';
import { createCss } from 'src/web/common/styles/createCss';
import { useTheme } from 'src/web/common/theme';
import PurchasesCost from './partial/purchasesCost';
import AssignmentRate from './partial/assignmentRate';
import RentalCost from './partial/rentalCost';

export interface SiteType {
  id?: string;
  name?: string;
}
export type EntitiesEnumType = `${AssetsTypesEnum}` | 'all';

const DashboardPage: FC = () => {
  const [selectedAsset, setSelectedAsset] = useState<EntitiesEnumType>('all');
  const [selectedSite, setselectedSite] = useState<SiteType | undefined>();

  const { translate } = useTranslate();
  const { theme } = useTheme();

  const graphStyle = createCss(`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media (max-width: ${theme.breakpoints.sm + 1}px) {
    grid-template-columns: 1fr;
  }
    `);

  return (
    <div>
      <PageHeaderBasic title={translate('global.dashboard.welcome.title')} />
      <Filter
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        selectedSite={selectedSite}
        setSelectedSite={setselectedSite}
      />
      <Highlights selectedAsset={selectedAsset} selectedSite={selectedSite} />
      <div className={graphStyle}>
        <AssignmentRate selectedAsset={selectedAsset} selectedSite={selectedSite} />
        <RentalCost selectedAsset={selectedAsset} selectedSite={selectedSite} />
        <AssetAvailability selectedAsset={selectedAsset} selectedSite={selectedSite} />
        <PurchasesCost selectedAsset={selectedAsset} selectedSite={selectedSite} />
      </div>
    </div>
  );
};

export default DashboardPage;
