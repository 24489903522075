import { useEffect, useState } from 'react';
import { useCore } from 'src/web/common/core';

const usePurchasesCost = (assetType?: string, siteId?: string) => {
  const { dashboardUseCase } = useCore();
  // FIXME: Should be of type DashboardBuyingCostStatsType
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  async function getBuyingCostStats() {
    setIsLoading(true);
    setError(null);
    try {
      const response = await dashboardUseCase.getBuyingCostStats(assetType, siteId);

      setData(response);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (assetType !== undefined && siteId !== undefined) {
      getBuyingCostStats();
    }
  }, [assetType, siteId]);

  return {
    data,
    isLoading,
    error,
  };
};

export default usePurchasesCost;
