import { type FC, type PropsWithChildren } from 'react';
import GuiThemeProvider, {
  useTheme as useGuiTheme,
  type GuiTheme,
  type GuiThemeConfig,
} from 'src/web/design_system/Theme';
import * as palettes from './raw/palettes';
import * as typography from './raw/typography';
import { pxToRem } from '../utils/styles';
import * as design from './raw/design';
import { useCore } from '../core';
import { DEFAULT_LANGUAGE } from '../config/constants';

export type Theme = GuiTheme;

const lightTheme: GuiThemeConfig = {
  palettes: {
    primary: palettes.PRIMARY,
    secondary: palettes.SECONDARY,
    info: palettes.INFO,
    success: palettes.SUCCESS,
    warning: palettes.WARNING,
    error: palettes.ERROR,
    neutral: palettes.NEUTRAL,
    tertiary: palettes.TERTIARY,
    gray: palettes.GRAY,
  },
  shape: {
    smallRadius: 6,
    defaultRadius: 8,
    containerPadding: 24,
  },
  colors: {
    black: palettes.NEUTRAL[900],
    white: '#fff',
    brand: '#ff5912',
    mainBg: palettes.NEUTRAL['50'],
    contentBg: '#fff',
    primaryTextColor: palettes.NEUTRAL[800],
    secondaryTextColor: palettes.NEUTRAL['500'],
    disabledTextColor: palettes.NEUTRAL['300'],
    activeAction: palettes.NEUTRAL['700'], //target icons!
    hoverAction: palettes.NEUTRAL['50'],
    selectedAction: palettes.PRIMARY['100'],
    focusAction: palettes.PRIMARY['100'],
    disabledAction: palettes.NEUTRAL['300'],
  },
  shadows: [
    design.BOX_SHADOWS.size_3,
    design.BOX_SHADOWS.size_9,
    design.BOX_SHADOWS.size_15,
    design.BOX_SHADOWS.size_20,
  ],
  typography: {
    default: {
      fontFamily: typography.FONT_FAMILIES.default,
      fontSize: typography.FONT_SIZES.text_default,
      fontWeightNormal: typography.FONT_WEIGHTS.regular,
      fontWeightMedium: typography.FONT_WEIGHTS.semibold,
      fontWeightBold: typography.FONT_WEIGHTS.bold,
    },
    pageTitle: {
      fontWeight: typography.FONT_WEIGHTS.heavy,
      lineHeight: typography.LINE_HEIGHTS.compact,
      fontSize: pxToRem(typography.FONT_SIZES.title_1),
    },
    sectionTitle: {
      fontWeight: typography.FONT_WEIGHTS.heavy,
      lineHeight: typography.LINE_HEIGHTS.compact,
      fontSize: pxToRem(typography.FONT_SIZES.title_2),
    },
    elementTitle: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.title_3),
    },
    textExtraLarge: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.large,
      fontSize: pxToRem(typography.FONT_SIZES.text_extraLarge),
    },
    textLarge: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.text_large),
    },
    textMedium: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.text_default),
    },
    textNormal: {
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.text_default),
    },
    textSmall: {
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.text_small),
    },
    textAction: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.large,
      fontSize: pxToRem(typography.FONT_SIZES.text_small),
      textTransform: 'capitalize',
    },
    linkLarge: {
      fontWeight: typography.FONT_WEIGHTS.bold,
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.link_large),
      textTransform: 'capitalize',
    },
    linkMedium: {
      fontWeight: typography.FONT_WEIGHTS.regular,
      lineHeight: typography.LINE_HEIGHTS.default,
      fontSize: pxToRem(typography.FONT_SIZES.link_default),
      textTransform: 'capitalize',
    },
    linkSmall: {
      fontWeight: typography.FONT_WEIGHTS.regular,
      lineHeight: typography.LINE_HEIGHTS.large,
      fontSize: pxToRem(typography.FONT_SIZES.link_small),
      textTransform: 'capitalize',
    },
  },
};

export const useTheme = useGuiTheme;

const NewThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const core = useCore();
  const userLanguage = core.store.getState((state) => state.user.current?.preferredLanguage);
  const currentLocale = (userLanguage ?? DEFAULT_LANGUAGE).split('-')[0];

  return (
    <GuiThemeProvider
      defaultMode="light"
      themeOptions={{ light: lightTheme, dark: lightTheme }}
      currentLocale={currentLocale}
    >
      {children}
    </GuiThemeProvider>
  );
};

export default NewThemeProvider;
