export function formatNumber(num: number, rounded = false, digits = 1): string {
  const formatWithSuffix = (value: number, suffix: string) =>
    value % 1 === 0 ? `${Math.trunc(value)}${suffix}` : `${value.toFixed(digits)}${suffix}`;

  if (num >= 1000000) {
    return formatWithSuffix(num / 1000000, 'M');
  }
  if (num >= 10000) {
    return formatWithSuffix(num / 1000, 'k');
  }
  if (num >= 100) {
    return formatWithSuffix(num, '');
  }

  if (rounded) {
    return Math.round(num).toString();
  }

  return num % 1 === 0 ? num.toString() : num.toFixed(digits);
}

export function nFormater(num: number, digits: number): string {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
}
