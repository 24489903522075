import Ajv, { ErrorObject, type ValidateFunction } from 'ajv/dist/2019';
// include the support to the 'format' field in the schema
import AjvFormats from 'ajv-formats';
// include missing formats (including 'iri-reference') to the validator according the draft from 2019.
// but infortunally this dependency is not written with Typescript.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AjvDraft2019 from 'ajv-formats-draft2019';
import { JsonSchema } from 'core/swagger';
import FormValidationClient, {
  FormValidationError,
  FormValidationErrors,
  FormValidationHandler,
} from 'core/services/form/form.port';
import { FORM_FLATTEN_SEPARATOR } from 'core/common/constants';

class AjvAdapter implements FormValidationClient {
  client: Ajv;

  // constructor
  constructor() {
    this.client = new Ajv({
      strict: false,
      allErrors: true,
      strictRequired: true,
      unicodeRegExp: false,
      messages: false,
    });

    AjvFormats(this.client);
    AjvDraft2019(this.client);
  }

  // process data
  process(schema: JsonSchema): FormValidationHandler {
    const handler = this.client.compile(schema);

    return (data) => {
      const valid = handler(data);
      if (!valid) {
        // DEBUG: Only display on development
        if (process.env.NODE_ENV === 'development') {
          console.error(`Validation errors for data: ${JSON.stringify(data)}`);

          handler.errors?.forEach((error) => {
            const errorDetails = {
              message: error.message,
              instancePath: error.instancePath,
              schemaPath: error.schemaPath,
              keyword: error.keyword,
              params: error.params,
            };
            console.error(`Error: ${JSON.stringify(errorDetails, null, 2)}`);
          });
        }
      }

      return this.mapValidationErrors(handler.errors);
    };
  }

  // map the returned errors
  private mapValidationErrors(errors: ValidateFunction['errors']): FormValidationErrors {
    return Object.fromEntries(
      (errors ?? []).map((error) => [
        this.formatErrorFieldName(error),
        this.formatErrorsKeyword(error),
      ]),
    );
  }

  // build the field name
  private formatErrorFieldName(error: ErrorObject): string {
    let keyPart: string[] = [];

    if (error.instancePath.length > 0) {
      const slice = error.instancePath.split('/').filter((el) => el.length > 0);

      keyPart = [...keyPart, ...slice];
    }

    if (error.keyword === 'required') {
      keyPart.push(error.params.missingProperty);
    }

    return keyPart.join(FORM_FLATTEN_SEPARATOR);
  }

  // adapt the returned keyword to the allowed keyword
  private formatErrorsKeyword(error: ErrorObject): FormValidationError {
    switch (error.keyword) {
      case 'required':
        return {
          keyword: 'required',
        };
      case 'enum':
        return {
          keyword: 'enum',
        };
      case 'pattern':
        return {
          keyword: 'pattern',
        };
      case 'format': {
        if (error.params?.format === 'email') {
          return {
            keyword: 'formatEmail',
          };
        }
        if (error.params?.format === 'date' || error.params?.format === 'date-time') {
          return {
            keyword: 'formatDate',
          };
        }
        return {
          keyword: 'format',
        };
      }
      case 'minLength':
        return {
          keyword: 'minLength',
          reasons: {
            min: String(error.params.limit),
          },
        };
      case 'maxLength':
        return {
          keyword: 'maxLength',
          reasons: {
            max: String(error.params.limit),
          },
        };
      case 'minimum':
        return {
          keyword: 'minimum',
          reasons: {
            min: String(error.params.limit),
          },
        };
      case 'maximum':
        return {
          keyword: 'maximum',
          reasons: {
            max: String(error.params.limit),
          },
        };
      case 'exclusiveMinimum':
        return {
          keyword: 'exclusiveMinimum',
          reasons: {
            min: String(error.params.limit),
          },
        };
      case 'exclusiveMaximum':
        return {
          keyword: 'exclusiveMaximum',
          reasons: {
            max: String(error.params.limit),
          },
        };
      default:
        return {
          keyword: 'default',
        };
    }
  }
}

export default AjvAdapter;
