import { useMemo } from 'react';
import { DEFAULT_CUSTOM_ASSET_NAME, RolesEnum } from 'core/common/constants';
import Icon, { type IconProps } from 'src/web/components/Icon';
import { useCore } from '../core';
import useTranslate from '../translate/useTranslate';

interface RolesDisplay {
  key: string;
  label: string;
  icon: React.FC<IconProps>;
}

const useDisplayRoles = (roles: string[]): RolesDisplay[] => {
  const { rolesUseCase } = useCore();
  const { translate } = useTranslate();
  const { store } = useCore();
  const customAssetTitle =
    store.getState((state) => state.legalEntity.settings?.settings?.customAssetTitle) ||
    DEFAULT_CUSTOM_ASSET_NAME;

  const manualOrder = [
    // COMMON
    RolesEnum.ROLE_MY_LEGAL_ENTITY,
    RolesEnum.ROLE_ADMIN_PERSON,
    // ENTITY
    RolesEnum.ROLE_ADMIN_VEHICLE,
    RolesEnum.ROLE_ADMIN_COMPUTER,
    RolesEnum.ROLE_ADMIN_LICENCE,
    RolesEnum.ROLE_ADMIN_SERVICE,
    RolesEnum.ROLE_ADMIN_TELEPHONE,
    RolesEnum.ROLE_ADMIN_TELEPHONE_LINE,
    RolesEnum.ROLE_ADMIN_CARD,
    RolesEnum.ROLE_ADMIN_CUSTOM_ASSET,
    // PARAMS
    RolesEnum.ROLE_ADMIN_USER,
    RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION,
  ];

  const displayedIcon = (role: string) => {
    switch (role) {
      case RolesEnum.ROLE_ADMIN_USER:
        return Icon.RoleUser;
      case RolesEnum.ROLE_ADMIN_PERSON:
        return Icon.RolePerson;
      case RolesEnum.ROLE_USER_NOTIFICATION:
        return Icon.RoleNotification;
      case RolesEnum.ROLE_ADMIN_VEHICLE:
        return Icon.RoleVehicle;
      case RolesEnum.ROLE_ADMIN_COMPUTER:
        return Icon.RoleComputer;
      case RolesEnum.ROLE_ADMIN_TELEPHONE:
        return Icon.RoleTelephone;
      case RolesEnum.ROLE_ADMIN_TELEPHONE_LINE:
        return Icon.RoleTelephoneLine;
      case RolesEnum.ROLE_ADMIN_CARD:
        return Icon.RoleCard;
      case RolesEnum.ROLE_ADMIN_SERVICE:
        return Icon.RoleService;
      case RolesEnum.ROLE_ADMIN_LICENCE:
        return Icon.RoleLicence;
      case RolesEnum.ROLE_DISTRIBUTOR:
        return Icon.RoleDistributor;
      case RolesEnum.ROLE_MY_LEGAL_ENTITY:
        return Icon.RoleLegalEntity;
      case RolesEnum.ROLE_ADMIN_CONTRACTUAL_COMMITMENT:
        return Icon.RoleContractualCommitment;
      case RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION:
        return Icon.RoleLegalEntityNotification;
      case RolesEnum.ROLE_ADMIN_CUSTOM_FORM:
        return Icon.RoleCustomForm;
      case RolesEnum.ROLE_ADMIN_CUSTOM_ASSET:
        return Icon.Autofill;
      default:
        return Icon.Random;
    }
  };

  const filteredRoles = useMemo(
    () => roles.filter((role) => !rolesUseCase.getShadowRoles().includes(role)),
    [roles],
  );

  /**
   * Check if the custom asset title is the default one 'title'
   */
  const isCustomAssetContentTitle = useMemo(
    () => customAssetTitle.toLowerCase() === DEFAULT_CUSTOM_ASSET_NAME,
    [customAssetTitle],
  );

  /**
   * Check if the user has the role 'ROLE_MY_LEGAL_ENTITY'
   */
  const isLegalEntity = roles.includes(RolesEnum.ROLE_MY_LEGAL_ENTITY);

  /**
   * Filter out roles that are not needed
   * 
   * - Sort roles by manual order
   * - Display customAsset role if content of the settings.customAssetTitle is different from the default one
   * - Filter out 'ROLE_MY_LEGAL_ENTITY' if the user has the role 'ROLE_MY_LEGAL_ENTITY'
   */
  const orderedRoles = filteredRoles
    .filter((role) => !(isCustomAssetContentTitle && role === RolesEnum.ROLE_ADMIN_CUSTOM_ASSET))
    .filter((role) => isLegalEntity || role !== RolesEnum.ROLE_MY_LEGAL_ENTITY)
    .sort((a, b) => {
      const indexA = manualOrder.indexOf(a as RolesEnum);
      const indexB = manualOrder.indexOf(b as RolesEnum);
      return (
        (indexA !== -1 ? indexA : manualOrder.length) -
        (indexB !== -1 ? indexB : manualOrder.length)
      );
    });

  return orderedRoles.map((item) => ({
    key: item,
    label:
      item === RolesEnum.ROLE_ADMIN_CUSTOM_ASSET
        ? customAssetTitle
        : translate(`enums.EnumUserRolesAvailableType.${item}.label`),
    icon: displayedIcon(item),
  }));
};

export default useDisplayRoles;
