import { FC, useEffect, useState } from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { useTheme } from 'src/web/common/theme';
import { createCss } from 'src/web/common/styles/createCss';
import useBreakpoint from 'src/web/common/utils/useBreakpoints';

interface GaugeProps {
  value?: number;
  valueMax?: number;
  startAngle?: number;
  endAngle?: number;
  height?: number;
  width?: number;
  color?: string;
  total?: number;
}

const GaugeChart: FC<GaugeProps> = ({
  value = 0,
  startAngle = -90,
  endAngle = 90,
  height = 300,
  valueMax = 100,
  color,
}) => {
  const { theme } = useTheme();
  const isMeduimScreenSize = useBreakpoint('down', 'lg');
  const width = !isMeduimScreenSize ? 320 : 250;

  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    let start: number | null = null;
    const duration = 500;

    const animate = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const roundedValue = +(Math.round(value * 100) / 100).toFixed(0);
      const newValue = Math.min(
        +(Math.round(roundedValue * (progress / duration) * 100) / 100).toFixed(1),
        roundedValue,
      );
      setAnimatedValue(newValue);
      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [value]);

  const styles = createCss(`
    & .${gaugeClasses.valueText} {
      font-size: 30px;
      font-weight:bold;
    }
    & .${gaugeClasses.valueArc} {
      fill: url(#gradientColor);
      stroke-width: 6px !important;
    }
    & .${gaugeClasses.referenceArc} {
      stroke: ${theme.palettes.primary?.[50]};
      stroke-width: 8px; 
      fill: ${theme.palettes.primary?.[50]};
    }
  `);

  return (
    <div className={styles}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradientColor" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: color ?? theme.palettes.primary?.[200], stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: color ?? theme.palettes.primary?.[700], stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
      </svg>
      <Gauge
        width={width}
        height={height}
        startAngle={startAngle}
        endAngle={endAngle}
        value={animatedValue}
        cornerRadius="10%"
        outerRadius="100%"
        valueMax={valueMax}
        text={`${animatedValue} %`}
      />
    </div>
  );
};

export default GaugeChart;
