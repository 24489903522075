import { FC, Fragment, useEffect, useState } from 'react';
import useSite from '../hooks/useSites';
import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import Icon from 'src/web/components/Icon';
import Skeleton from 'src/web/design_system/Skeleton';
import PopoverMenu from 'src/web/components/PopoverMenu';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import AddSiteModal from './addSiteModal';
import EditSiteModal from './editSiteModal';
import { joinValues } from 'src/web/common/utils/stringify';
import useTranslate from 'src/web/common/translate/useTranslate';

const SitesPartial: FC = () => {
  const { translate } = useTranslate();
  const { data: sites, isLoading, loadData, deleteData } = useSite();
  const [currentOpenMenuId, setCurrentOpenMenuId] = useState<string>();
  const [currentOpenEditModalId, setCurrentEditModalId] = useState<string>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const menu = (id: string) => (
    <PopoverMenu
      isOpen={currentOpenMenuId === id}
      items={[
        {
          label: translate('legalEntity.attachmentSites.edit.button'),
          onClick: () => {
            setCurrentEditModalId(id);
            setCurrentOpenMenuId(undefined);
          },
        },
        {
          label: translate('legalEntity.attachmentSites.delete.button'),
          onClick: () => {
            deleteData(id);
            setCurrentOpenMenuId(undefined);
          },
        },
      ]}
      onChange={(state) => setCurrentOpenMenuId(state ? id : undefined)}
    />
  );

  const addButton = (
    <ButtonIcon onClick={() => setIsCreateModalOpen(true)} edge="end">
      <Icon.Add />
    </ButtonIcon>
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Card.Container>
        <Card.Toolbar secondaryContent={addButton}>
          <Text variant="sectionTitle">{translate('legalEntity.attachmentSites.title')}</Text>
        </Card.Toolbar>
        <Card.Content>
          <Displayer innerSpacing={2}>
            {isLoading ? (
              <>
                {[1, 2, 3].map((v) => (
                  <Skeleton key={v} width="100%" height="100px" />
                ))}
              </>
            ) : (
              <>
                {sites?.length !== 0 ? (
                  sites?.map((site) => {
                    // in reality this values can't be undefined
                    const siteId = site.id ?? '';
                    return (
                      <Fragment key={siteId}>
                        <Card.Container border="line" floatingActionContent={menu(siteId)}>
                          <Card.Content compact>
                            <Text fontWeight={600} tag="div">
                              {site.label}
                            </Text>
                            <Text variant="textSmall" tag="div">
                              {joinValues([
                                site.location?.streetName,
                                site.location?.streetNameExtra,
                              ])}
                            </Text>
                            <Text variant="textSmall" tag="div">
                              {joinValues([
                                site.location?.postcode,
                                site.location?.city,
                                site.location?.country,
                              ])}
                            </Text>
                          </Card.Content>
                        </Card.Container>

                        {currentOpenEditModalId === siteId && (
                          <EditSiteModal
                            siteId={siteId}
                            open={true}
                            onClose={() => setCurrentEditModalId(undefined)}
                            onSubmit={() => loadData()}
                          />
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <Text>{translate('legalEntity.attachmentSites.noOptions.message')}</Text>
                )}
              </>
            )}
          </Displayer>
        </Card.Content>
      </Card.Container>

      <AddSiteModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={() => loadData()}
      />
    </>
  );
};

export default SitesPartial;
