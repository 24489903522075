/**
 * Assets references
 * All existing assets
 * returning the asset route path because this is necessary on typing
 * while the display name is only used as a value
 */

export const enum EntitiesEnum {
  'vehicle' = 'vehicles',
  'computer' = 'computers',
  'card' = 'cards',
  'service' = 'services',
  'licence' = 'licences',
  'telephone' = 'telephones',
  'telephoneLine' = 'telephone-lines',
  'person' = 'persons',
  'user' = 'users',
  'task' = 'tasks-items',
  'customAsset' = 'custom-assets',
}

/**
 * Assets names
 * Because their names are different than their route names...
 */
export const enum AssetsTypesEnum {
  'vehicle' = 'vehicle',
  'computer' = 'computer',
  'card' = 'card',
  'service' = 'service',
  'licence' = 'licence',
  'telephone' = 'telephone',
  'telephoneLine' = 'telephoneLine',
  'person' = 'person',
  'user' = 'users',
  'task' = 'tasks-items',
  'customAsset' = 'customAsset',
}

/**
 * Type from asset
 * @example
 */
export const AssetsTypesMap: Record<EntitiesEnum, AssetsTypesEnum> = {
  [EntitiesEnum.vehicle]: AssetsTypesEnum.vehicle,
  [EntitiesEnum.computer]: AssetsTypesEnum.computer,
  [EntitiesEnum.card]: AssetsTypesEnum.card,
  [EntitiesEnum.service]: AssetsTypesEnum.service,
  [EntitiesEnum.licence]: AssetsTypesEnum.licence,
  [EntitiesEnum.telephone]: AssetsTypesEnum.telephone,
  [EntitiesEnum.telephoneLine]: AssetsTypesEnum.telephoneLine,
  [EntitiesEnum.person]: AssetsTypesEnum.person,
  [EntitiesEnum.user]: AssetsTypesEnum.user,
  [EntitiesEnum.task]: AssetsTypesEnum.task,
  [EntitiesEnum.customAsset]: AssetsTypesEnum.customAsset,
} as const;

/**
 * All allowed roles
 */
export const enum RolesEnum {
  'ROLE_USER' = 'ROLE_USER',
  'ROLE_USER_NOTIFICATION' = 'ROLE_USER_NOTIFICATION',
  'ROLE_DISTRIBUTOR' = 'ROLE_DISTRIBUTOR',
  'ROLE_MY_LEGAL_ENTITY' = 'ROLE_MY_LEGAL_ENTITY',
  'ROLE_ADMIN_VEHICLE' = 'ROLE_ADMIN_VEHICLE',
  'ROLE_ADMIN_USER' = 'ROLE_ADMIN_USER',
  'ROLE_ADMIN_PERSON' = 'ROLE_ADMIN_PERSON',
  'ROLE_ADMIN_ASSET' = 'ROLE_ADMIN_ASSET',
  'ROLE_ADMIN_COMPUTER' = 'ROLE_ADMIN_COMPUTER',
  'ROLE_ADMIN_TELEPHONE' = 'ROLE_ADMIN_TELEPHONE',
  'ROLE_ADMIN_TELEPHONE_LINE' = 'ROLE_ADMIN_TELEPHONE_LINE',
  'ROLE_ADMIN_LICENCE' = 'ROLE_ADMIN_LICENCE',
  'ROLE_ADMIN_CARD' = 'ROLE_ADMIN_CARD',
  'ROLE_ADMIN_CONTRACTUAL_COMMITMENT' = 'ROLE_ADMIN_CONTRACTUAL_COMMITMENT',
  'ROLE_ADMIN_SERVICE' = 'ROLE_ADMIN_SERVICE',
  'ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION' = 'ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION',
  'ROLE_ADMIN_CUSTOM_FORM' = 'ROLE_ADMIN_CUSTOM_FORM',
  'ROLE_ADMIN_INVOICE' = 'ROLE_ADMIN_INVOICE',
  'ROLE_ADMIN_CUSTOM_ASSET' = 'ROLE_ADMIN_CUSTOM_ASSET',
}

/**
 * Used to filter routes
 */
export const enum RouteParamsPrefix {
  'filterBy' = 'filterBy',
  'autocomplete' = 'autocomplete',
  'translatable' = 'translatable',
  'unreserved' = 'unreserved',
  'order' = 'order',
  'search' = 'search',
}

/**
 * Locales key names
 * Returns normalized values
 * @example
 * fr = 'fr-FR'
 */
export enum LocalesEnum {
  fr = 'fr-FR',
  en = 'en-US',
}

/**
 * Roles from entiry
 * @example
 * const computerRoles = EntitiesRolesMap[EntitiesEnum.computer]
 */
export const EntitiesRolesMap: Record<EntitiesEnum, ReadonlyArray<RolesEnum>> = {
  [EntitiesEnum.vehicle]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_VEHICLE],
  [EntitiesEnum.computer]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_COMPUTER],
  [EntitiesEnum.card]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_CARD],
  [EntitiesEnum.service]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_SERVICE],
  [EntitiesEnum.licence]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_LICENCE],
  [EntitiesEnum.telephone]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_TELEPHONE],
  [EntitiesEnum.telephoneLine]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_TELEPHONE_LINE],
  [EntitiesEnum.person]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_PERSON],
  [EntitiesEnum.user]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_USER],
  [EntitiesEnum.task]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_CUSTOM_FORM],
  [EntitiesEnum.customAsset]: [RolesEnum.ROLE_USER, RolesEnum.ROLE_ADMIN_CUSTOM_ASSET],
} as const;

export const AssetRolesMap: Record<AssetsTypesEnum, RolesEnum> = {
  [AssetsTypesEnum.vehicle]: RolesEnum.ROLE_ADMIN_VEHICLE,
  [AssetsTypesEnum.computer]: RolesEnum.ROLE_ADMIN_COMPUTER,
  [AssetsTypesEnum.card]: RolesEnum.ROLE_ADMIN_CARD,
  [AssetsTypesEnum.service]: RolesEnum.ROLE_ADMIN_SERVICE,
  [AssetsTypesEnum.licence]: RolesEnum.ROLE_ADMIN_LICENCE,
  [AssetsTypesEnum.telephone]: RolesEnum.ROLE_ADMIN_TELEPHONE,
  [AssetsTypesEnum.telephoneLine]: RolesEnum.ROLE_ADMIN_TELEPHONE_LINE,
  [AssetsTypesEnum.person]: RolesEnum.ROLE_ADMIN_PERSON,
  [AssetsTypesEnum.user]: RolesEnum.ROLE_ADMIN_USER,
  [AssetsTypesEnum.task]: RolesEnum.ROLE_ADMIN_CUSTOM_FORM,
  [AssetsTypesEnum.customAsset]: RolesEnum.ROLE_ADMIN_CUSTOM_ASSET,
};

export const FORM_FLATTEN_SEPARATOR = '_';

export const enum AppEventsNames {
  'OnUnauthorized' = 'OnUnauthorized',
  'OnServerError' = 'OnServerError',
  'OnLogin' = 'OnLogin',
  'OnLogout' = 'OnLogout',
}

export const enum TaskTopics {
  'peopleOnBoarding' = 'peopleOnBoarding',
  'peopleOffBoarding' = 'peopleOffBoarding',
}

export const NOTIFICATIONS_CONDITION_CUSTOM = 'byPreDefinedDatetime';

export const DEFAULT_CUSTOM_ASSET_NAME = 'titre';
