import { FC, PropsWithChildren } from 'react';
import { useTheme } from 'src/web/design_system/Theme';
import { Link as RouterLink } from 'react-router-dom';
import { createCss, mergeCss } from 'src/web/common/styles/createCss';

interface LinkProps {
  className?: string;
  to?: string;
  onClick?: () => void;
  target?: '_blank';
}

const linkStyle = (color?: string, hoverColor?: string) =>
  createCss(`
  &, &:hover, &:visited {
    text-decoration: unset;
  }
  &, &:visited {
    color: ${color ?? 'inherit'};
  }
  &:hover {
    color: ${hoverColor ?? 'inherit'};
  }
`);

const Link: FC<PropsWithChildren<LinkProps>> = ({ to, onClick, className, children, target }) => {
  const { theme } = useTheme();

  if (!to) {
    return <>{children}</>;
  }

  return (
    <RouterLink
      to={to}
      className={mergeCss(
        linkStyle(theme.palettes.primary?.[500], theme.palettes.primary?.[700]),
        className,
      )}
      onClick={onClick}
      target={target}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
