import FormService from 'core/services/form/form.service';
import AssignmentUseCase from 'core/usecases/assignment.usecase';
import AuthUseCase from 'core/usecases/auth.usecase';
import BoardingUseCase from 'core/usecases/boarding.usecase';
import CurrentUserUsecase from 'core/usecases/currentUser.usecase';
import DeleteAssetUseCase from 'core/usecases/deleteAsset.usecase';
import AssetDetailsUseCase from 'core/usecases/details.usecases';
import DocumentsUsecase from 'core/usecases/documents.usecase';
import InventoryUseCase from 'core/usecases/inventory.usecase';
import LegalEntityUsecase from 'core/usecases/legalEntity.usecase';
// import NotificationConditionsUsecase from 'core/usecases/notificationConditions.usecase';
// import NotificationPreviewUseCase from 'core/usecases/notificationPreview.usecase';
import PersonUseCase from 'core/usecases/person.usecase';
import RolesUseCase from 'core/usecases/roles.usecase';
import SetAssetUseCase from 'core/usecases/setAsset.usecase';
import SetPersonUseCase from 'core/usecases/setPerson.usecase';
import SetUserUseCase from 'core/usecases/setUser.usecase';
import SetVehicleUseCase from 'core/usecases/setVehicleMileage';
import SitesUseCase from 'core/usecases/sites.usecase';
import SivinUseCase from 'core/usecases/sivin.usecase';
import TasksItemsUseCase from 'core/usecases/tasksItems.usecase';
import UserUseCase from 'core/usecases/user.usecase';
// import UserNotificationsSubscriptionUseCase from 'core/usecases/userNotificationsSubscription.usecase';
import CacheService from './services/cache/cache.service';

// import NotificationsConfigUsecase from './usecases/notificationsConfig.usecase';
import GuestUsecase from 'core/usecases/guest.usecase';
import CustomAssetCategoryUseCase from 'core/usecases/customAssetCategory.usecase';
import DashboardUseCase from 'core/usecases/dashboard.usecase';

export default {
  inventoryUsecase: InventoryUseCase,
  authUsecase: AuthUseCase,
  formService: FormService,
  assignmentUseCase: AssignmentUseCase,
  setAssetUsercase: SetAssetUseCase,
  setPersonUsecase: SetPersonUseCase,
  assetDetailsUseCase: AssetDetailsUseCase,
  sivinUseCase: SivinUseCase,
  // userNotificationsSubscriptionUseCase: UserNotificationsSubscriptionUseCase,
  currentUserUsecase: CurrentUserUsecase,
  guestUsecase: GuestUsecase,
  setVehicleUseCase: SetVehicleUseCase,
  personUsercase: PersonUseCase,
  legalEntityUsecase: LegalEntityUsecase,
  sitesUseCase: SitesUseCase,
  documentUsecase: DocumentsUsecase,
  setUserUseCase: SetUserUseCase,
  rolesUseCase: RolesUseCase,
  userUseCase: UserUseCase,
  deleteAssetUseCase: DeleteAssetUseCase,
  // notificationPreviewUseCase: NotificationPreviewUseCase,
  tasksItemsUseCase: TasksItemsUseCase,
  boardingUseCase: BoardingUseCase,
  // notificationConditionsUsecase: NotificationConditionsUsecase,
  cacheService: CacheService,
  customAssetCategoryUseCase: CustomAssetCategoryUseCase,
  dashboardUseCase: DashboardUseCase,
};
