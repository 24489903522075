import { createCss } from 'src/web/common/styles/createCss';
import { Theme } from 'src/web/common/theme';

const NAV_WIDTH_XL = 280;
const NAV_WIDTH_MD = 90;

export const rootContainer = (drawerOpen: boolean, theme: Theme) =>
  createCss(`
  display: grid; 
  grid-template-rows: 80px 1fr; 
  gap: 0px 0px; 
  height: 100vh;
  overflow: hidden;
  box-sizing: content-box;
  background-color: ${theme.palettes.neutral?.[50]};
  transition: grid-template-columns .3s cubic-bezier(.75,.76,.24,.98);
  @media (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: 1fr; 
    grid-template-areas: 
      "HeaderContainer"
      "ContentContainer"; 
  };
  @media (min-width: ${theme.breakpoints.md + 1}px) {
    grid-template-columns: ${drawerOpen ? NAV_WIDTH_XL : NAV_WIDTH_MD}px 1fr; 
    grid-template-areas: 
      "SideContainer HeaderContainer"
      "SideContainer ContentContainer"; 
  };
  @media (min-width: ${theme.breakpoints.lg + 1}px) {
    grid-template-columns: ${drawerOpen ? NAV_WIDTH_XL : NAV_WIDTH_MD}px 1fr; 
  }
`);

export const headerContainer = (theme: Theme) =>
  createCss(`
  grid-area: HeaderContainer;
  @media (max-width: ${theme.breakpoints.md}px) {
    box-shadow: ${theme.shadows[2]};
  }
`);

export const sideContainer = (theme: Theme) =>
  createCss(`
  height: 100%;
  @media (max-width: ${theme.breakpoints.md}px) {
    width:auto;
    padding-inline:10px;
  }
  
  @media (min-width: ${theme.breakpoints.md + 1}px) {
    grid-area: SideContainer;
  }
`);

export const wrapContentContainer = (theme: Theme) =>
  createCss(`
  grid-area: ContentContainer;
  overflow: auto;
  padding: 32px 64px;
  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 32px;
  }
`);

export const contentContainer = (_theme: Theme) =>
  createCss(`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`);

export const containerStyle = (_theme: Theme) =>
  createCss(`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`);
export const scrollBarStyle = (drawerOpen?: boolean) =>
  createCss(`
  scrollbar-width: ${!drawerOpen && 'none'};
`);

export const reduceMenuButtonStyle = (theme: Theme) =>
  createCss(`
  border: 1px dashed ${theme.colors.activeAction}30 !important ;
  color: ${theme.colors.secondaryTextColor} !important;
  position: absolute !important;
  width:30px;
  height:30px;
  top: 22px;
  right: -15px; 
  z-index:1; 
  backdrop-filter:blur(1px);
`);

export const headContainer = createCss(`
  padding: 16px;
`);

export const logoWrapper = createCss(`
  max-width: 75%;
`);

export const content = (open: boolean) =>
  createCss(`
  padding: ${open ? '16px' : '4px'} !important
`);
