import { FC } from 'react';
import { animationCss, createCss } from 'src/web/common/styles/createCss';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import CounterAnimation from '../CounterAnimation/CounterAnimation';
import { useTheme } from 'src/web/common/theme';

interface LinearGaugeProps {
  value?: number;
  max?: number;
  label?: string;
  color?: string;
  mode?: 'base' | 'percent';
}

const LinearGauge: FC<LinearGaugeProps> = ({ value = 0, max = 1, label, color, mode = 'base' }) => {
  const { theme } = useTheme();

  color = color ?? theme.palettes.primary?.[700];
  const barValue = (value / max) * 100;
  const translateAnimation = animationCss(`
    from { transform: translateX(-100%) }
    to { transform: translateX(-${100 - barValue}%) }
`);

  const barStyle = createCss(`
    width: 100%;
    background-color: ${color};
    border-radius: 5px;
    height: 5px;
    animation: ${translateAnimation} 0.7s ease-in-out forwards;
  `);

  const containerBarStyle = createCss(`
    width:100%;
    background-color:${theme.palettes.neutral?.[50]};
    border-radius:5px;
    height:5px;
    overflow:hidden;
`);
  const percentageStyle = createCss(`
    background-color: ${color};
     display: flex;
        justify-content: center;
        flex-direction: row;
        color:#fff;
        font-size: 0.875rem;
        font-weight: 700; 
        border-radius: .5rem;
        padding: 0.125rem .81rem;
`);

  return (
    <Displayer innerSpacing={1.25}>
      <Displayer alignItems="center" layout={'row'} justifyContent={'space-between'}>
        <Text color={theme.palettes.neutral?.[800]}>{label}</Text>
        <div className={percentageStyle}>
          <Displayer fullWidth={false} layout={'row'} innerSpacing={0.5}>
            <Text variant="textNormal" fontWeight={400}>
              <CounterAnimation
                targetNumber={mode === 'base' ? value : barValue}
                duration={500}
                growLevel={1}
              />
            </Text>
            {mode === 'base' ? (
              <>
                <Text variant="textNormal" fontWeight={400}>
                  /
                </Text>
                <Text variant="textNormal" fontWeight={400}>
                  {max}
                </Text>
              </>
            ) : (
              <Text variant="textNormal" fontWeight={400}>
                %
              </Text>
            )}
          </Displayer>
        </div>
      </Displayer>
      <div className={containerBarStyle}>
        <div className={barStyle}></div>
      </div>
    </Displayer>
  );
};

export default LinearGauge;
