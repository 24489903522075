import { useEffect, useState } from 'react';
import { AssetRolesMap, AssetsTypesEnum } from 'src/core/common/constants';
import { useCore } from 'src/web/common/core';
import useBreakpoint from 'src/web/common/utils/useBreakpoints';
import Icon from 'src/web/components/Icon';
import { EntitiesEnumType } from '..';
import { FilterStyle } from '../styles';
import AssetsFilter from './assetsFilter';
import AssetsFilterSelect from './assetsFilterSelect';
import SitesFilter from './sitesFilter';

export interface SiteType {
  id?: string;
  name?: string;
}

interface DashboardFilterProps {
  selectedAsset: EntitiesEnumType;
  setSelectedAsset: (val: EntitiesEnumType) => void;
  selectedSite?: SiteType;
  setSelectedSite?: (val: SiteType | undefined) => void;
}

const ungrantedAssets: (keyof typeof AssetsTypesEnum)[] = [
  AssetsTypesEnum.vehicle,
  AssetsTypesEnum.computer,
  AssetsTypesEnum.licence,
  AssetsTypesEnum.service,
  AssetsTypesEnum.telephone,
  AssetsTypesEnum.telephoneLine,
  AssetsTypesEnum.card,
  
  // FIXME: Uncomment this when the backend fix the useAsstAvailability()
  // filtered based on roles
  // AssetsTypesEnum.customAsset,
];

const FilterPartial = ({
  selectedAsset,
  setSelectedAsset,
  selectedSite,
  setSelectedSite,
}: DashboardFilterProps) => {
  const [filteredSites, setFilteredSites] = useState<SiteType[]>([]);
  const isMobile = useBreakpoint('down', 'sm');

  const { store } = useCore();
  const isUserGranted = () => store.getState((state) => state.user.current?.roles);

  const grantedAssets = ungrantedAssets.filter((asset) => {
    return isUserGranted()?.includes(AssetRolesMap[asset as AssetsTypesEnum]);
  });

  // Only include "Tous" (all) if the user has all the assets
  const hasAllAssets = grantedAssets.length === ungrantedAssets.length;

  // FIXME: The request should send only data that the user has access to based on the roles
  // const assetsWithCustomAsset: (keyof typeof AssetsTypesEnum | 'all')[] = ['all', ...grantedAssets]
  const assets: (keyof typeof AssetsTypesEnum | 'all')[] = hasAllAssets
    ? ['all', ...grantedAssets]
    : grantedAssets;

  useEffect(() => {
    // automatically select the first asset if none is selected or "Tous" is unavailable
    if (!hasAllAssets && selectedAsset === 'all') {
      const firstAvailableAsset = assets[0];
      setSelectedAsset(firstAvailableAsset as EntitiesEnumType);
    }
    // set the first asset if selectedAsset is undefined or invalid
    if (!selectedAsset || !assets.includes(selectedAsset as keyof typeof AssetsTypesEnum)) {
      const firstAvailableAsset = assets[0];
      setSelectedAsset(firstAvailableAsset as EntitiesEnumType);
    }
  }, [selectedAsset, assets, hasAllAssets]);

  const getAssetIcon = (type: string) => {
    switch (type) {
      case AssetsTypesEnum.vehicle:
        return <Icon.Vehicle size="small" />;
      case AssetsTypesEnum.card:
        return <Icon.Card size="small" />;
      case AssetsTypesEnum.service:
        return <Icon.Service size="small" />;
      case AssetsTypesEnum.licence:
        return <Icon.Licence size="small" />;
      case AssetsTypesEnum.telephone:
        return <Icon.Telephone size="small" />;
      case AssetsTypesEnum.telephoneLine:
        return <Icon.TelephoneLine size="small" />;
      case AssetsTypesEnum.computer:
        return <Icon.Computer size="small" />;
      case AssetsTypesEnum.customAsset:
        return <Icon.Autofill size="small" />;
      default:
        return <Icon.Random size="small" />;
    }
  };

  return (
    <div className={FilterStyle(isMobile)}>
      {!isMobile ? (
        <AssetsFilter
          getAssetIcon={getAssetIcon}
          assets={assets}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset as (val: string) => void}
        />
      ) : (
        <AssetsFilterSelect
          getAssetIcon={getAssetIcon}
          assets={assets}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset as (val: string) => void}
        />
      )}
      <SitesFilter
        filteredSites={filteredSites}
        setFilteredSites={setFilteredSites}
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
      />
    </div>
  );
};

export default FilterPartial;
