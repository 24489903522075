import { InputAdornment } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { type FC, ReactNode, useState } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';

// todo: move somewhere else relative to the design system core (soon in Gac-UI)
const timeAdapted = (value?: Date) => {
  return dayjs(value);
};

// todo: move somewhere else relative to the design system core (soon in Gac-UI)
const timeUnadapted = (adaptedValue: dayjs.Dayjs | null) => {
  if (adaptedValue) {
    return adaptedValue.toDate();
  }

  return adaptedValue;
};

const NOW_NAME = 'now';
const READONLY_NAME = 'readonly';
const DISABLED_NAME = 'disabled';

export interface DatePickerProps {
  label?: string;
  value?: Date | null;
  defaultValue?: Date;
  minDate?: Date | typeof NOW_NAME;
  maxDate?: Date | typeof NOW_NAME;
  onChange?: (date: Date | undefined) => void;
  state?: typeof READONLY_NAME | typeof DISABLED_NAME;
  error?: boolean;
  fullWidth?: boolean;
  // except a single value only values relative to each other are possible:
  // day + month, month + year, day + month + year, but not day + year
  displayDate?: ('day' | 'month' | 'year')[];
  openCalendarTo?: 'day' | 'month' | 'year';
  isRequired?: boolean;
  startExtra?: ReactNode;
  endExtra?: ReactNode;
}

const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  defaultValue,
  //add a min date to avoid having a 3 digits year (The default behavior)
  minDate = new Date('01/01/1900'),
  maxDate,
  onChange,
  state,
  error,
  fullWidth = true,
  isRequired,
  displayDate,
  openCalendarTo = 'day',
  startExtra,
  endExtra,
}) => {
  const { translate } = useTranslate();

  const [isValid, setIsValid] = useState(true);

  const isValidDate = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    const min = minDate === NOW_NAME ? new Date() : minDate;
    const max = maxDate === NOW_NAME ? new Date() : maxDate;
    if (min && date < min) {
      return false;
    }
    if (max && date > max) {
      return false;
    }
    return true;
  };

  const handleChange = (date: Date | null) => {
    const valid = isValidDate(date);
    setIsValid(valid);

    if (onChange && valid) {
      onChange(date ?? undefined);
    }
  };

  const customOpenIcon: FC = () => <>{endExtra}</>;

  return (
    <LocalizationProvider
      localeText={{
        fieldDayPlaceholder: () => translate('global.datepicker.day'),
        fieldMonthPlaceholder: () => translate('global.datepicker.month'),
        fieldYearPlaceholder: () => translate('global.datepicker.year'),
      }}
    >
      <MuiDatePicker
        value={value ? timeAdapted(value) : null}
        defaultValue={defaultValue ? timeAdapted(defaultValue) : undefined}
        onChange={(newValue) =>
          typeof newValue !== 'string' && handleChange(timeUnadapted(newValue))
        }
        readOnly={state === READONLY_NAME}
        disabled={state === DISABLED_NAME}
        views={displayDate}
        openTo={openCalendarTo}
        closeOnSelect
        disablePast={minDate === NOW_NAME}
        disableFuture={maxDate === NOW_NAME}
        minDate={!minDate || minDate === NOW_NAME ? undefined : timeAdapted(minDate)}
        maxDate={!maxDate || maxDate === NOW_NAME ? undefined : timeAdapted(maxDate)}
        slots={endExtra ? { openPickerIcon: customOpenIcon } : {}}
        slotProps={{
          field: { clearable: true, onClear: () => handleChange(null) },
          textField: {
            label: label,
            error: error || !isValid,
            fullWidth: fullWidth,
            required: isRequired,

            InputProps: {
              startAdornment: startExtra ? (
                <InputAdornment position="start">{startExtra}</InputAdornment>
              ) : undefined,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
