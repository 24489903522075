import * as React from 'react';
import Toggle from 'src/web/design_system/Toggle';
import { assetsFilterStyle, ToggleButtonStyle, assetGroupStyle } from '../styles';
import { useTheme } from 'src/web/common/theme';
import Text from 'src/web/design_system/Text';
import useTranslate from 'src/web/common/translate/useTranslate';
import { nanoid } from 'nanoid';

export interface AssetsFilterProps {
  assets: string[];
  getAssetIcon: (type: string) => JSX.Element;
  selectedAsset: string;
  setSelectedAsset: (val: string) => void;
}
const AssetsFilter: React.FC<AssetsFilterProps> = ({
  assets,
  getAssetIcon,
  selectedAsset,
  setSelectedAsset,
}) => {
  const { theme } = useTheme();
  const { translate } = useTranslate();

  const handleAlignment = (newAlignment: string | null) => {
    if (newAlignment !== null) {
      setSelectedAsset(newAlignment);
    }
  };

  return (
    <div className={assetsFilterStyle}>
      <Toggle.Group
        value={selectedAsset}
        exclusive
        onChange={handleAlignment}
        className={assetGroupStyle}
      >
        {assets.map((asset) => (
          <Toggle.Item
            key={nanoid()}
            value={asset}
            className={ToggleButtonStyle(theme, asset === selectedAsset)}
          >
            {asset === 'all' ? (
              <Text variant="textNormal">{translate('dashboard.filter.material.all')}</Text>
            ) : (
              getAssetIcon(asset)
            )}
          </Toggle.Item>
        ))}
      </Toggle.Group>
    </div>
  );
};

export default AssetsFilter;
