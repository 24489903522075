import { FC } from 'react';
import { BarChart as MuiBarChart } from '@mui/x-charts/BarChart';
import Text from 'src/web/design_system/Text';
import {
  axisClasses,
  AxisConfig,
  barElementClasses,
  BarSeriesType,
  ChartsXAxisProps,
} from '@mui/x-charts';
import { useTheme } from 'src/web/common/theme';
import { mergeCss } from 'src/web/common/styles/createCss';
import { nFormater } from 'src/core/common/transformNumber';

type ScaleType = 'time' | 'point' | 'linear' | 'band' | 'log' | 'pow' | 'sqrt' | 'utc';

interface LineChartProps {
  data?: number[];
  className?: string;
  series?: BarSeriesType[];
  alineX?: string[];
  color?: string;
  title?: string;
  xAxis?: number | string[];
  height?: number;
  width?: number;
  scale: ScaleType;
  xAxisLabel?: string;
  yAxisLabel?: string;
  layout?: 'horizontal' | 'vertical';
  barMaxWidth?: number;
}

const LineChart: FC<LineChartProps> = ({
  title,
  className,
  data,
  height = 350,
  layout = 'vertical',
  color,
  scale = 'band',
  xAxisLabel,
  yAxisLabel,
  alineX,
}) => {
  const { theme } = useTheme();
  color = color ?? theme.palettes.primary?.[300];
  const valueFormatter = (value: number | null) =>
    value !== null
      ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value)
      : '';

  return (
    <div>
      {title && <Text variant="elementTitle">{title}</Text>}
      <MuiBarChart
        className={mergeCss(className)}
        data-testid="line-chart-svg"
        sx={{
          [`.${barElementClasses.root}`]: {
            strokeWidth: 0,
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              stroke: theme.colors.white,
            },
            [`.${axisClasses.tickLabel}`]: {
              fill: theme.palettes.neutral?.[500],
            },
          },
        }}
        series={[
          {
            data: data,
            color,
            valueFormatter: valueFormatter,
          },
        ]}
        yAxis={[
          {
            scaleType: 'linear',
            data: data,
            label: yAxisLabel,
            fill: theme.colors.white,
            disableLine: true,
            valueFormatter: (value) => `${nFormater(value, 1)} €`,
          },
        ]}
        margin={{ left: 60 }}
        xAxis={
          layout === 'vertical'
            ? [
                {
                  scaleType: scale,
                  data: alineX,
                  label: xAxisLabel,
                  categoryGapRatio: 0.4,
                } as AxisConfig<'band', any, ChartsXAxisProps>,
              ]
            : []
        }
        height={height}
        borderRadius={10}
        grid={{ horizontal: true }}
      />
    </div>
  );
};

export default LineChart;
