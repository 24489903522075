import { FC, useEffect, useState, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import MenuItemSide from './MenuSideItem';
import useTranslate from 'src/web/common/translate/useTranslate';
import GrantRoles from 'src/web/components/Grant';
import { MenuItemConfig, assetMenuItemsConfig, dashboardMenuItemsConfig } from './menuItemsConfig';
import { nanoid } from 'nanoid';
import { cx } from '@emotion/css';
import * as styles from './styles';
import { useTheme } from 'src/web/common/theme';
import Menu from 'src/web/design_system/Menu';
import Text from 'src/web/design_system/Text';
import { useCore } from 'src/web/common/core';

interface SideNavProps {
  open: boolean;
}

const SideNav: FC<SideNavProps> = ({ open }) => {
  const { theme } = useTheme();
  const location = useLocation();
  const { store } = useCore();
  const [activePath, setActivePath] = useState<string>('');
  const { translate } = useTranslate();
  const customAssetTitle = store.getState(
    (state) => state.legalEntity.settings?.settings?.customAssetTitle,
  );

  const isPathSelected = (path: string) => {
    return activePath.length > 0 && activePath.match(`^${path}.*`) !== null;
  };

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const renderLabel = (labelKey: string, customLabel?: string) => {
    return !open ? (
      <Text variant="textSmall" textOverflow align="center" whiteSpace="wrap">
        {customLabel ? customLabel : translate(labelKey)}
      </Text>
    ) : customLabel ? (
      customLabel
    ) : (
      translate(labelKey)
    );
  };

  const renderMenuItem = (item: MenuItemConfig, customLabel?: string) => {
    const isSelected = isPathSelected(item.route);
    const menuItem = (
      <MenuItemSide
        label={renderLabel(item.labelKey, customLabel)}
        startIcon={<span className={styles.iconStyles(theme, isSelected)}>{item.icon}</span>}
        component={Link}
        to={item.route}
        isSelected={isSelected}
        className={cx(!open && styles.smallStyle, styles.MenuIconStyle(open))}
      />
    );

    return item.roles ? <GrantRoles includes={item.roles}>{menuItem}</GrantRoles> : menuItem;
  };

  return (
    <>
      <Menu.Container>
        {dashboardMenuItemsConfig.map((item) => (
          <Fragment key={nanoid()}>{renderMenuItem(item)}</Fragment>
        ))}
      </Menu.Container>
      <Menu.Container title={open && translate('global.nav.asset.title')}>
        {assetMenuItemsConfig.map((item) => (
          <Fragment key={nanoid()}>
            {renderMenuItem(item, item.custom ? customAssetTitle : undefined)}
          </Fragment>
        ))}
      </Menu.Container>
    </>
  );
};

export default SideNav;
