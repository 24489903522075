import { useEffect, type FC } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import useSite from '../../legalEntity/hooks/useSites';
import { SiteType } from './filterPartial';
import { FilterItemStyle } from '../styles';
import { useTheme } from 'src/web/common/theme';
import Skeleton from 'src/web/design_system/Skeleton';

interface SitesFilterProps {
  filteredSites: SiteType[];
  setFilteredSites: (filteredSites: SiteType[]) => void;
  selectedSite?: SiteType | undefined;
  setSelectedSite?: (val: SiteType | undefined) => void;
}

const SitesFilter: FC<SitesFilterProps> = ({
  filteredSites,
  setFilteredSites,
  selectedSite,
  setSelectedSite,
}) => {
  const { translate } = useTranslate();
  const { theme } = useTheme();

  const { data: sites, isLoading, loadData } = useSite();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setFilteredSites([{ id: 'all', name: translate('dashboard.filter.material.all') }, ...sites]);
    setSelectedSite?.(filteredSites.at(0));
  }, [sites]);

  const handleSearch = (searchText: string): void => {
    const searchResult = [{ id: 'all', name: translate('dashboard.filter.material.all') }, ...sites]
      .filter((site) => site.name?.toLowerCase().includes(searchText.toLowerCase()))
      .map((el) => ({ name: el.name, id: el.id }));
    setFilteredSites(searchResult);
  };

  const handleChange = (ids?: string[]): void => {
    const getSite = filteredSites.filter((element) => element.id === ids?.at(0));
    setSelectedSite?.(getSite.at(0));
  };

  if (isLoading) return <Skeleton shape="text" />;

  return (
    <div className={FilterItemStyle(theme)}>
      <Autocomplete
        isFullWidth
        isRequired
        options={filteredSites?.map((site) => ({
          value: site.id ?? '',
          label: `${site.name}`,
        }))}
        onSearch={handleSearch}
        onChange={(value) => handleChange(value)}
        renderInput={(option) => option.label}
        renderOption={(option) => option.label}
        value={{
          label: selectedSite?.name ?? '',
          value: selectedSite?.id ?? '',
        }}
      />
    </div>
  );
};
export default SitesFilter;
