import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UseSetAssetFormProps } from './commonType';
import { ROUTE_UPDATE_TAG } from 'src/web/common/router/constantes';
import useToaster from 'src/web/design_system/Toaster/useToaster';
import useTranslate from 'src/web/common/translate/useTranslate';

export type UseAddAssetFormProps = UseSetAssetFormProps;

interface ApiResponse {
  status?: number;
  data: any;
}

const useEditAssetForm = ({ asset, baseRoute }: UseAddAssetFormProps) => {
  const { translate } = useTranslate();
  const { setAssetUsercase } = useCore();
  const navigate = useNavigate();
  const location = useLocation();
  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  // get asset id
  // FIXME: Sometimes, id is not loaded and we get back for nothing.
  const assetId = useMemo(() => {
    const chunks = location.pathname.split('/');
    if (chunks.slice(-1).includes(ROUTE_UPDATE_TAG)) {
      return chunks.splice(-2)[0];
    }

    navigate('/');
    return '';
  }, [location]);

  const { properties, required, initialValues, validate, submit } = useMemo(
    () => setAssetUsercase.getEditForm(asset, assetId),
    [asset],
  );

  const createErrorToast = (response: ApiResponse) => {
    toaster.createToast({
      message: `${translate(
        `${asset}.${response.data.violations[0].propertyPath}.label`,
      )} : ${translate(`assets.${response.data.violations[0].message}.label`)}`,
      variant: 'error',
      duration: 3000,
    });
  };

  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data): Promise<void> => {
      setIsLoading(true);
      try {
        // W-A of what?
        if (!data.asset_attachmentSite) {
          data = { ...data, asset_attachmentSite: null };
        }

        const response = (await submit(data)) as ApiResponse;
        
        // FIXME: Replace 202 status by 200 !
        // edit asset if success and navigate to the edited asset
        if (response.status === 202) {
          const id = (response.data as { id?: string }).id;
          navigate(`${baseRoute}/${id}?${queryString}`);
        } else if (response.status === 422) {
          createErrorToast(response);
        } else {
          // TODO: Display a general error toaster here
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Update form values from current data
  useEffect(() => {
    if (initialValues) {
      Promise.resolve(initialValues).then((values) => {
        for (const key in values) {
          form.imperativeChangeField(key, values[key]);
        }

        setIsLoading(false);
      });
    }
  }, [initialValues]);

  return {
    form,
    isLoading,
  };
};

export default useEditAssetForm;
