import MuiToggleButton from '@mui/material/ToggleButton';
import { FC, PropsWithChildren } from 'react';

interface ToggleButtonItemProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  fullWidth?: boolean;
  value: string;
  selected?: boolean;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: string) => void;
}

const ToggleButtonItem: FC<PropsWithChildren<ToggleButtonItemProps>> = ({
  children,
  className,
  size = 'small',
  disabled = false,
  fullWidth = false,
  value,
  selected,
  onChange,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <MuiToggleButton
      className={className}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      value={value}
      selected={selected}
      onClick={handleClick}
    >
      {children}
    </MuiToggleButton>
  );
};

export default ToggleButtonItem;
