import { useState, type FC, useRef, useEffect } from 'react';
import useAssetAssignmentPostForm from './useAssetAssignmentPostForm';
import Form from 'src/web/components/FormInput';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import Modal from 'src/web/components/Modal';
import Displayer from 'src/web/design_system/Displayer';
import Switch from 'src/web/design_system/Input/Switch';
import Grid from 'src/web/design_system/Grid';
import Button from 'src/web/components/Button';
import { useCore } from 'src/web/common/core';
import { parseIri } from 'core/common/utils/id';
import useTranslate from 'src/web/common/translate/useTranslate';
import useFormComponent from '../FormInput/useFormComponent';
import { EntitiesEnum } from 'src/core/common/constants';

interface AssetAssignmentPostModalProps {
  assetIri: string;
  onSubmit: () => void;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  endDateLastAssignement?: string;
  entity: EntitiesEnum;
}

const AssetAssignmentPostModal: FC<AssetAssignmentPostModalProps> = ({
  assetIri,
  isOpen,
  onClose,
  title,
  onSubmit,
  endDateLastAssignement,
  entity,
}) => {
  const { translate } = useTranslate();
  const { timeService, personUsercase } = useCore();
  const { formatFormMessage } = useFormComponent();

  const [generateAssignCertificate, setGenerateAssignCertificate] = useState(false);
  const [showFuturePersons, setShowFuturePersons] = useState<boolean>(false);
  const [personAssign, setPersonAssign] =
    useState<Awaited<ReturnType<typeof personUsercase.getPerson>>>();

  const { form, onSearchPersons, persons, handleReset, isLoading } = useAssetAssignmentPostForm({
    assetIri,
    afterSubmit: (status) => {
      if (status) {
        onSubmit();
        onClose();
      }
    },
    personIri: personAssign?.['@id'] ?? '',
    generateAssignCertificate,
    entity,
  });

  const formRef = useRef<FormContainerRef>(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handlePersonChange = (value?: string[]) => {
    form.items.person.handleChange(value?.[0] ?? undefined);
    if (value) {
      const loadRes = async () => {
        const resCurrentPerson = await personUsercase.getPerson(
          parseIri(value[0]).pathParts.slice(-1)[0],
        );
        if (resCurrentPerson) {
          setPersonAssign(resCurrentPerson);
        }
      };
      loadRes();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      handleReset();
    }
  }, [isOpen]);

  const minDateFromPersonSelected =
    personAssign && timeService.fromBackend(personAssign.entryAt).getTime();
  const mindateFromLastAssignement =
    endDateLastAssignement && timeService.fromBackend(endDateLastAssignement).getTime();

  const minDate =
    minDateFromPersonSelected &&
    mindateFromLastAssignement &&
    minDateFromPersonSelected > Number(mindateFromLastAssignement)
      ? timeService.fromBackend(personAssign.entryAt)
      : timeService.fromBackend(endDateLastAssignement ?? '');

  const actionArea = (
    <Displayer layout="row" justifyContent="center" innerSpacing={3}>
      <Button.Custom onClick={() => onClose()} variant="outlined" fullWidth={false}>
        {translate('assets.assignment.post.modal.cancel')}
      </Button.Custom>
      <Button.Custom
        isLoading={isLoading}
        onClick={() => handleSubmit()}
        fullWidth={false}
        disabled={!form.isDirty || form.hasErrors}
      >
        {translate('assets.assignment.post.modal.submit')}
      </Button.Custom>
    </Displayer>
  );
  return (
    <>
      <Modal title={title} open={isOpen} onClose={() => onClose()} footer={actionArea}>
        <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
          <Displayer innerSpacing={2}>
            <Displayer bottomSpacing={2}>
              <Switch
                value={showFuturePersons}
                onChange={(value) => setShowFuturePersons(value)}
                label={translate('assets.assignment.post.modal.showFuture')}
              />
            </Displayer>
            <Autocomplete
              label={translate('assets.assignment.post.modal.collab')}
              isRequired
              noOptionsText={translate('global.autocomplete.noOptions')}
              isError={!!form.items.person.errors}
              errorMessage={formatFormMessage(form.items.person)}
              options={persons.map((person) => ({
                value: person['@id'] ?? '',
                lastname: person.lastname,
                firstname: person.firstname,
                jobTitle: person.jobTitle,
              }))}
              onSearch={(value) => onSearchPersons(value, !showFuturePersons)}
              onChange={(value) => handlePersonChange(value)}
              renderOption={(option) =>
                `${option.lastname} ${option.firstname} - ${option.jobTitle}`
              }
              renderInput={(option) =>
                `${option.lastname} ${option.firstname} - ${option.jobTitle}`
              }
            />
            <Grid.Container>
              <Grid.Item>
                <Form.DateAdapted
                  minDate={timeService.manipulate(minDate, { day: 1 })}
                  item={form.items.startAt}
                  label={translate('assets.assignment.post.modal.startDate')}
                  maxDate={
                    personAssign?.exitAt ? timeService.fromBackend(personAssign.exitAt) : undefined
                  }
                />
              </Grid.Item>
              <Grid.Item>
                <Form.DateAdapted
                  minDate={
                    form.items.startAt.formValue
                      ? timeService.manipulate(
                          timeService.fromBackend(form.items.startAt.formValue.toString()),
                          { day: 1 },
                        )
                      : undefined
                  }
                  item={form.items.endAt}
                  label={translate('assets.assignment.post.modal.endDate')}
                  maxDate={
                    personAssign?.exitAt ? timeService.fromBackend(personAssign.exitAt) : undefined
                  }
                />
              </Grid.Item>
            </Grid.Container>
            <Switch
              label={translate('persons.assign.generateDeliveryAssignmentCertificate.label')}
              value={generateAssignCertificate}
              onChange={(value) => setGenerateAssignCertificate(value)}
            />
          </Displayer>
        </FormContainer>
      </Modal>
    </>
  );
};

export default AssetAssignmentPostModal;
