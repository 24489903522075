import FocusLayout from 'src/web/layouts/FocusLayout';
import FleezyIcon from 'src/web/components/FleezyIcon';
import Text from 'src/web/design_system/Text';
import Button from 'src/web/components/Button';
import { FC, useEffect, useRef, useState } from 'react';
import Alert from 'src/web/design_system/Alert';
import Icon from 'src/web/components/Icon';
import { themeColors } from 'src/web/common/theme/constant';
import { ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN } from 'src/web/common/router/constantes';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { authCardStyle } from '../styles';
import useSetPassword from './useSetPassword';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import useTranslate from 'src/web/common/translate/useTranslate';
import { isString } from 'src/core/common/utils/predicatesType';
import usePasswordValidator from '../usePasswordValidator';

interface PasswordVerifyProps {
  condition: boolean;
  text: string;
}

const DefinePassword: FC = () => {
  const formRef = useRef<FormContainerRef>(null);
  const { token } = useParams();
  const { form, succesSubmit, errorSubmit, isLoading } = useSetPassword(token ?? '');
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);

  const [searchParams] = useSearchParams();
  const mailPathQueryValue = searchParams.get('email');

  const { hasLowercase, hasNumber, hasSpecialChar, hasUppercase, isLongEnough } =
    usePasswordValidator(
      isString(form.items.password.formValue) ? form.items.password.formValue : '',
    );

  const isPasswordReady =
    hasUppercase && hasLowercase && hasNumber && hasSpecialChar && isLongEnough;

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleCancel = () => {
    navigate(ROUTE_LOGIN);
  };

  const handleBackToLostPassword = () => {
    navigate(ROUTE_FORGOT_PASSWORD);
  };

  const handleBackToLoginPage = () => {
    navigate(`${ROUTE_LOGIN}?email=${mailPathQueryValue}`);
  };

  const isDisabledForm = isPasswordReady && !form.hasErrors && form.isDirty && isPasswordMatch;

  //depend of password value change and confirm password value change, check if it's a match !
  useEffect(() => {
    setIsPasswordMatch(confirmPassword === form.items.password.formValue);
  }, [confirmPassword, form.items.password.formValue]);

  const PasswordVerify: FC<PasswordVerifyProps> = ({ condition, text }) => {
    return (
      <>
        <Displayer layout={'row'} innerSpacing={0.5}>
          {condition ? (
            <Icon.CheckCricle size="small" color="success" />
          ) : (
            <Icon.CircleOutline size="small" />
          )}
          <Text variant="textSmall" color={condition ? 'green' : 'inherit'}>
            {text}
          </Text>
        </Displayer>
      </>
    );
  };

  return (
    <FocusLayout>
      <FleezyIcon width={'lg'} />
      <Card.Container className={authCardStyle}>
        {!succesSubmit ? (
          <Card.Content>
            <Displayer bottomSpacing={3}>
              <Text
                variant="sectionTitle"
                color={themeColors.blackColor}
                align="left"
                fontWeight={900}
              >
                {translate('auth.definePasswordPage.title')}
              </Text>
            </Displayer>
            {token ? (
              <Displayer innerSpacing={2}>
                <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
                  <Displayer innerSpacing={2}>
                    <Displayer layout={'row'} innerSpacing={1}>
                      <Icon.Person />
                      <Text>{mailPathQueryValue}</Text>
                    </Displayer>
                    <Form.PasswordAdapted
                      label={translate('auth.password.label')}
                      item={form.items.password}
                    />
                    <Displayer layout={'column'} innerSpacing={0.5}>
                      <Text variant="textSmall" fontWeight={600}>
                        {translate('auth.definePassword.instructions.label')}
                      </Text>
                      <PasswordVerify
                        condition={isLongEnough}
                        text={translate('auth.definePassword.check.isLongEnough')}
                      />
                      <PasswordVerify
                        condition={hasUppercase}
                        text={translate('auth.definePassword.check.hasUpperCase')}
                      />
                      <PasswordVerify
                        condition={hasLowercase}
                        text={translate('auth.definePassword.check.hasLowerCase')}
                      />
                      <PasswordVerify
                        condition={hasNumber}
                        text={translate('auth.definePassword.check.hasNumber')}
                      />
                      <PasswordVerify
                        condition={hasSpecialChar}
                        text={translate('auth.definePassword.check.hasSpecialChar')}
                      />
                    </Displayer>
                    <Form.Password
                      label={translate('auth.definePasswordPage.confirmPassword.label')}
                      value={confirmPassword}
                      error={!isPasswordMatch}
                      message={
                        !isPasswordMatch
                          ? translate('auth.definePasswordPage.passwordMatch.error')
                          : ''
                      }
                      onChange={(value) => setConfirmPassword(value ?? '')}
                    />
                  </Displayer>
                </FormContainer>
                {errorSubmit && (
                  <Alert icon={<Icon.Close />} mode="error">
                    {translate('auth.definePasswordPage.error.message')}
                  </Alert>
                )}
              </Displayer>
            ) : (
              <Alert icon={<Icon.Fail />} mode="error">
                {translate('auth.definePasswordPage.invlidLink.description')}
              </Alert>
            )}
          </Card.Content>
        ) : (
          <Card.Content>
            <Alert icon={<Icon.Done />} mode="success">
              {translate('auth.definePasswordPage.succes.message')}
            </Alert>
          </Card.Content>
        )}
        {!succesSubmit ? (
          <Card.Footer>
            {token ? (
              <>
                <Button.Custom variant="outlined" onClick={handleCancel}>
                  {translate('auth.definePasswordPage.cancel')}
                </Button.Custom>
                <Button.Custom
                  onClick={() => handleSubmit()}
                  disabled={!isDisabledForm}
                  isLoading={isLoading}
                >
                  {translate('auth.definePasswordPage.submit')}
                </Button.Custom>
              </>
            ) : (
              <Button.Custom onClick={handleBackToLostPassword}>
                {translate('auth.definePasswordPage.goToLostPasswordPage.cta')}
              </Button.Custom>
            )}
          </Card.Footer>
        ) : (
          <Card.Footer>
            <Button.Custom onClick={handleBackToLoginPage}>
              {translate('auth.definePasswordPage.goToLoginPage.cta')}
            </Button.Custom>
          </Card.Footer>
        )}
      </Card.Container>
    </FocusLayout>
  );
};
export default DefinePassword;
