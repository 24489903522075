import { AssetsTypesEnum } from 'src/core/common/constants';

/**
 * Compares two strings based on a custom order defined by the AssetsTypesEnum
 * @param a - The first string to compare
 * @param b - The second string to compare
 * @returns A negative number if `a` should be sorted before `b`, a positive number if `a` should be sorted after `b`, or 0 if they are equal
 */
export function customOrder(a: string, b: string): number {
  const orderedAssets: AssetsTypesEnum[] = [
    AssetsTypesEnum.vehicle,
    AssetsTypesEnum.computer,
    AssetsTypesEnum.licence,
    AssetsTypesEnum.service,
    AssetsTypesEnum.telephone,
    AssetsTypesEnum.telephoneLine,
    AssetsTypesEnum.card,
    AssetsTypesEnum.customAsset
  ];

  return orderedAssets.indexOf(a as AssetsTypesEnum) - orderedAssets.indexOf(b as AssetsTypesEnum);
}
