import { FC, useEffect, useState } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import Card from 'src/web/design_system/Card';
import Chart from 'src/web/design_system/Chart';
import Skeleton from 'src/web/design_system/Skeleton';
import Text from 'src/web/design_system/Text';
import { SiteType } from '..';
import useRentalCost from '../hooks/useRentalCost';
import { AssetAvailabilityTitleStyle, widgetStyle } from '../styles';
import ErrorWidget from './errorWidget';
import { AssetsTypesEnum } from 'src/core/common/constants';
import { useCore } from 'src/web/common/core';

interface AssetAvailabilityProps {
  selectedAsset?: string;
  selectedSite?: SiteType;
}

const RentalCost: FC<AssetAvailabilityProps> = ({ selectedAsset, selectedSite }) => {
  const assetType = selectedAsset === 'all' ? '' : selectedAsset;
  const siteId = selectedSite?.id === 'all' ? '' : selectedSite?.id;
  const { isLoading, data, error } = useRentalCost(assetType, siteId);
  const { translate } = useTranslate();
  const [errorMessage, seterrorMessage] = useState('');
  const { store } = useCore();


  useEffect(() => {
    if (error) {
      seterrorMessage(translate('dashboard.unavailableData.message'));
    } else {
      seterrorMessage('');
    }
  }, [error, data]);

  const costs = data?.assetCostStats?.map((el: { cost: number }) => el.cost);
  const yearly = data?.assetCostStats?.map((el: { year: string }) => '' + el.year);

  const availableData: number | undefined | null = data?.assetCostStats?.reduce(
    (acc: number, el: { cost: number }) => acc + el.cost,
    0,
  );

  if (errorMessage.length) {
    return (
      <ErrorWidget title={translate('dashboard.annualRentalCost.title')} message={errorMessage} />
    );
  }

  const customAssetTitle = store.getState((state) => state.legalEntity.settings?.settings?.customAssetTitle);
  const assetTitle = assetType
  ? ` ${translate('global.terms.of')} ${
      assetType === AssetsTypesEnum.customAsset
        ? customAssetTitle
        : translate(`${assetType}s.title`).toLowerCase()
    }`
  : '';

  return (
    <Skeleton isLoading={isLoading} width={'100%'} height="400px">
      <Card.Container className={widgetStyle}>
        <div className={AssetAvailabilityTitleStyle}>
          <Text variant="elementTitle">
            {translate('dashboard.annualRentalCost.title')}
            {assetTitle}
          </Text>
        </div>
        {data && availableData ? (
          <Chart.Bare alineX={yearly} data={costs} height={400} scale="band" />
        ) : (
          <ErrorWidget message={translate('dashboard.unavailableData.message')} inCard={false} />
        )}
      </Card.Container>
    </Skeleton>
  );
};

export default RentalCost;
