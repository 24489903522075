import { createCss } from 'src/web/common/styles/createCss';
import { Theme } from 'src/web/common/theme';

export const FilterStyle = (isMobile: boolean) =>
  createCss(`
display: flex;
flex-direction: ${isMobile ? 'column' : 'row'} !important;
flex-wrap: wrap !important;
align-items: center;
gap: 1rem !important;
`);

export const widgetStyle = createCss(`
  padding: 5px;
  overflow: auto !important;
  word-wrap: break-word;
  max-height: 500px;
  ::-webkit-scrollbar-thumb {
          display: block;
          background: #d9d9d9;
          border-radius: 50px;
        };
  `);
export const messageWidgetStyle = (color?: string) =>
  createCss(`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:.5rem;
  padding: 2rem;
  min-height:300px;
  & .MuiSvgIcon-root{
  color:${color};
  font-size:4rem;
  }
  `);

export const assetsFilterStyle = createCss(`
    flex-grow: 1;
`);
export const assetGroupStyle = createCss(`
    width:100% !important;
    display: flex;
`);

export const FilterItemStyle = (theme: Theme) =>
  createCss(`
  flex-grow: 4;
  @media (max-width: ${theme.breakpoints.sm + 1}px) {
    width: 100%
  };
  `);

export const ToggleButtonStyle = (theme: Theme, isSelected: boolean) =>
  createCss(`
  padding: 0.53rem 1.375rem !important;
  background:${isSelected ? theme.palettes.primary?.[50] : theme.colors.white} !important;
  border: 1.2px solid ${theme.palettes.neutral?.[300]} !important;
  color:${isSelected && theme.palettes.primary?.[500]} !important;
  flex-grow: 1;
  `);

export const HighlightCardStyle = (isMobile: boolean) =>
  createCss(`
  border-radius: 0.5rem;
  padding:2rem 1.8rem ;
  ${isMobile ? 'width: 100%; !important' : ''}


  @media (max-width: ${450 + 1}px) {
     width:100%;
  };
  @media (min-width: ${960 + 1}px) {
     flex: 1;
  };

  `);
export const HighlightCardText = createCss(`
  // max-width: 9rem;
  `);
export const HighlightStyle = createCss(`
  margin-top:2.5rem;
  margin-bottom:1.5rem;
  display: flex;
  flex-direction: row;
  gap:1.5rem;
  @media (max-width: ${1200 + 1}px) {
    flex-wrap:wrap;
  };
  `);

// remove le top margin
export const AssetAvailabilityStyle = createCss(`
  margin-bottom:1.625rem;
  `);
export const AssetAvailabilityTitleStyle = createCss(`
  margin:25px;
  `);
export const guageStyle = createCss(`
   display: flex;
   justify-content: center;
  `);
