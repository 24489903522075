import { useEffect, useState } from 'react';
import { useCore } from 'src/web/common/core';

const useRentalCost = (assetType?: string, siteId?: string) => {
  const { dashboardUseCase } = useCore();
  // FIXME: Should be of type DashboardRentalCostStatsType
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  async function getRentalCost() {
    setIsLoading(true);
    setError(null);
    try {
      const resource = await dashboardUseCase.getRentalCostStats(assetType, siteId);
      setData(resource);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (assetType !== undefined && siteId !== undefined) {
      getRentalCost();
    }
  }, [assetType, siteId]);

  return {
    data,
    isLoading,
    error,
  };
};

export default useRentalCost;
