import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';
import { SwaggerRequestResponse } from '../swagger';

export type DashboardAssetAssignmentType = SwaggerRequestResponse<
  '/api/private/dashboard/asset-assignment-stats',
  'get'
>;

class DashboardAssetAssignmentEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathAll() {
    return exactString('/api/private/dashboard/asset-assignment-stats');
  }

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.http.requestFromSwagger(this.getPathAll(), 'get', options);
  };
}

export default DashboardAssetAssignmentEntity;
