import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { LocalesEnum, RolesEnum } from 'core/common/constants';
import UserEntity from 'core/entities/user.entity';
import { isString } from 'core/common/utils/predicatesType';

class CurrentUserUsecase implements Usecase {
  constructor(private store: AppStore, private userEntity: UserEntity) {}

  getRoles() {
    return [RolesEnum.ROLE_USER];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * REQUEST PASSWORD UPDATE
   */
  public async requestPasswordUpdate(email?: string) {
    const userIdentifier = email ?? this.store.getState((state) => state.user.current?.email);

    if (!isString(userIdentifier)) {
      return false;
    }

    return this.userEntity
      .passwordRetrieve({
        body: {
          username: userIdentifier,
        },
      })
      .then((response) => !!response.data);
  }

  /**
   * GET CURRENT USER
   */
  public async getCurrentUser() {
    return this.userEntity.getCurrent().then((response) => {
      if (response.data) {
        this.store.setState((state) => {
          state.user.current = response.data;
        });

        return true;
      }

      return false;
    });
  }

  /**
   * UPDATE CURRENT LANGAGE
   */
  public async updatePreferredLanguage(value: LocalesEnum): Promise<boolean> {
    const updateResponse = await this.userEntity
      .putCurrent({
        body: {
          preferredLanguage: value,
        },
      })
      .then((response) => !!response.data);

    const getResponse = await this.getCurrentUser();

    return updateResponse && getResponse;
  }

  /**
   * IS CURRENT USER
   */
  public isCurrentUser(userId: string): boolean {
    const currentUserId = this.store.getState((state) => state.user.current?.id);
    return currentUserId === userId;
  }
}

export default CurrentUserUsecase;
