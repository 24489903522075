import { Paper, Box, PaperOwnProps } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import Icon from 'src/web/components/Icon';
import Text from '../Text';

interface SelectEmblemProps {
  icon: ReactNode;
  label: string;
  state?: 'seleted' | 'default' | 'disabled';
  onClick?: () => void;
  selectedColor?: string;
  defaultColor?: string;
  disableColor?: string;
}

const SelectEmblem: FC<SelectEmblemProps> = ({
  icon,
  label,
  state,
  selectedColor,
  defaultColor,
  disableColor,
  onClick,
}) => {
  const isSelected = state !== 'default';
  const handleClick = () => {
    if (onClick && state !== 'disabled') {
      onClick();
    }
  };

  const paperStyle = useMemo((): Partial<PaperOwnProps> => {
    if (state !== 'seleted') {
      return {
        elevation: 5,
        variant: 'elevation',
      };
    }
    return {
      variant: 'outlined',
    };
  }, [state]);

  return (
    <Paper
      {...paperStyle}
      sx={{
        p: 2,
        gap: '1em',
        display: 'flex',
        width: 'auto',
        alignItems: 'center',
        backgroundColor: isSelected ? '#E8EEFA' : 'inherit',
        cursor: onClick && state !== 'disabled' ? 'pointer' : 'default',
        border: 'none',
        color: (theme) => {
          switch (state) {
            case 'seleted':
              return selectedColor ?? theme.palette.primary.main;
            case 'disabled':
              return disableColor ?? theme.palette.grey[400];
            default:
              return defaultColor ?? theme.palette.grey[600];
          }
        },
      }}
      onClick={handleClick}
      title={label}
    >
      <Box sx={{ opacity: 0.3 }}>{icon}</Box>
      <Text variant={'textNormal'}>{label}</Text>
      <Box sx={{ marginLeft: 'auto' }}>
        {isSelected ? <Icon.CheckBox /> : <Icon.CheckBoxOutline />}
      </Box>
    </Paper>
  );
};

export default SelectEmblem;
