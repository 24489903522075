import { FC } from 'react';
import { type FormItem } from 'src/web/common/form/types';
// import useFormComponent from './useFormComponent';
import InputDate, { type InputDateProps } from './InputDate';
import { useCore } from 'src/web/common/core';
import dayjs from 'dayjs';

// message is missing
type FormDateExcludedProps = keyof Pick<InputDateProps, 'error' | 'isRequired'>;

interface FormDateProps extends Omit<InputDateProps, FormDateExcludedProps> {
  item: FormItem;
}

const FormDate: FC<FormDateProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props);
    return <></>;
  }

  const { timeService } = useCore();
  // const { formatFormMessage } = useFormComponent();
  const value =
    typeof item.formValue == 'string' && item.formValue.length > 0
      ? timeService.fromBackend(item.formValue)
      : null;

  return (
    <InputDate
      value={value}
      isRequired={item.required}
      error={!!item.errors}
      // message={formatFormMessage(item)}
      onChange={(val) =>
        item.handleChange(val ? dayjs(val).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined)
      }
      {...props}
    />
  );
};

export default FormDate;
