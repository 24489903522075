import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';
import { SwaggerRequestResponse } from '../swagger';

export type DashboardAssetBuyingCostType = SwaggerRequestResponse<
  '/api/private/dashboard/asset-buying-cost-stats',
  'get'
>;
// todo rental type
export type DashboardAssetCurrentMonthCostType = SwaggerRequestResponse<
  '/api/private/dashboard/current-month-asset-cost-stats',
  'get'
>;

class DashboardAssetCostStatsEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathBuyingCostStats() {
    return exactString('/api/private/dashboard/asset-buying-cost-stats');
  }

  getPathRentalCostStats() {
    return exactString('/api/private/dashboard/asset-rental-cost-stats');
  }

  getPathCurrentMonthCostStats() {
    return exactString('/api/private/dashboard/current-month-asset-cost-stats');
  }

  getBuyingCostStats = async (
    options: RequestOptions<ReturnType<typeof this.getPathBuyingCostStats>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathBuyingCostStats(), 'get', options),
      {
        path: this.getPathBuyingCostStats(),
        method: 'get',
        payload: options,
      },
    );
  };

  getRentalCostStats = async (
    options: RequestOptions<ReturnType<typeof this.getPathRentalCostStats>, 'get'>,
  ) => {
    return this.http.requestFromSwagger(this.getPathRentalCostStats(), 'get', options);
  };

  getCurrentMonthCostStats = async (
    options: RequestOptions<ReturnType<typeof this.getPathCurrentMonthCostStats>, 'get'>,
  ) => {
    return this.http.requestFromSwagger(this.getPathCurrentMonthCostStats(), 'get', options);
  };
}

export default DashboardAssetCostStatsEntity;
